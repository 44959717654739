import { Card, CardActionArea, Box, CircularProgress, type SxProps } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { type ReactElement, useEffect, useMemo, useRef, useState } from "react";
import HiddenFileInput from "./HiddenFileInput";
import DeleteButton from "../buttons/DeleteButton";

/* trick CSS savoureux de vieux singe à qui on n'apprend pas
  à faire la grimace pour avoir un ratio de 1:1 (carré) en css */
const square = {
  width: "100%",
  height: 0,
  pb: "100%",
};

const absoluteZero: SxProps = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  m: "auto",
};

const sxCard: SxProps = {
  position: "relative",
  width: "100%",
};

const sxAddIcon: SxProps = {
  ...absoluteZero,
  fontSize: "60px",
};

enum PhotoInputState {
  EMPTY = "EMPTY",
  LOADING = "LOADING",
  DISPLAY_URL = "DISPLAY_URL",
  DISPLAY_BASE64 = "DISPLAY_BASE64",
}

type PhotoInputProps = {
  onNewBase64: (base64: string) => void;
  onBase64Removed: () => void;
  onUriRemoved: () => void;
  defaultImageUri?: string;
};

function PhotoInput({
  onNewBase64,
  onBase64Removed,
  onUriRemoved,
  defaultImageUri,
}: PhotoInputProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [base64, setBase64] = useState("");
  const [inputState, setInputState] = useState<PhotoInputState>(PhotoInputState.EMPTY);
  const inputRef = useRef<HTMLInputElement>(null);

  function selectImage(): void {
    if (inputState === PhotoInputState.EMPTY) {
      /* Clique artificiellement sur l'<HiddenFileInput /> */
      inputRef?.current?.click();
    }
  }

  function deleteImage(): void {
    if (inputState === PhotoInputState.DISPLAY_BASE64) {
      onBase64Removed();
    }
    if (inputState === PhotoInputState.DISPLAY_URL) {
      onUriRemoved();
    }
    setBase64("");
    setIsDeleted(true);
  }

  function onStartLoading(): void {
    setIsLoading(true);
  }

  function onNewBase64Data(base64Data: string): void {
    setBase64(base64Data);
    onNewBase64(base64Data);
    setIsLoading(false);
  }

  function onConvertingError(error: any): void {
    // TODO error handling
    console.error(error);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isLoading) {
      setInputState(PhotoInputState.LOADING);
    } else {
      if (base64 != null && base64 !== "") {
        setInputState(PhotoInputState.DISPLAY_BASE64);
      } else {
        if (defaultImageUri != null && defaultImageUri !== "" && !isDeleted) {
          setInputState(PhotoInputState.DISPLAY_URL);
        } else {
          setInputState(PhotoInputState.EMPTY);
        }
      }
    }
  }, [isLoading, defaultImageUri, base64, isDeleted]);

  const squareWithImage: SxProps = useMemo(() => {
    let imageString = "";
    if (inputState === PhotoInputState.DISPLAY_BASE64) imageString = base64;
    if (inputState === PhotoInputState.DISPLAY_URL) imageString = defaultImageUri ?? "";
    return {
      ...square,
      backgroundImage: `url(${imageString})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };
  }, [defaultImageUri, base64, inputState]);

  return (
    <Card variant="outlined" sx={sxCard}>
      {/* Input caché artificiellement clické pour permettre de sélectionner une photo */}
      <HiddenFileInput
        ref={inputRef}
        onStartLoading={onStartLoading}
        onNewBase64Data={onNewBase64Data}
        onError={onConvertingError}
      />
      <CardActionArea onClick={selectImage}>
        <Box sx={squareWithImage}>
          {inputState === PhotoInputState.EMPTY && (
            <Box sx={square}>
              <AddAPhotoIcon color="secondary" sx={sxAddIcon} />
            </Box>
          )}
          {inputState === PhotoInputState.LOADING && (
            <CircularProgress disableShrink sx={absoluteZero} />
          )}
        </Box>
      </CardActionArea>
      {(inputState === PhotoInputState.DISPLAY_URL ||
        inputState === PhotoInputState.DISPLAY_BASE64) && (
        <DeleteButton deleteImage={deleteImage} />
      )}
    </Card>
  );
}

export default PhotoInput;
