import { type ReactElement, useEffect } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 * Page appelée après déconnexion de l'utilisateur sur la platefome Microsoft Online
 */
function FlushPage(): ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return <Container maxWidth="md">Vous êtes à présent déconnecté.</Container>;
}

export default FlushPage;
