import NotFoundErrorPage from "pages/errors/NotFoundErrorPage";
import ArticleForm from "components/organisms/ArticleForm";
import LoadingPage from "pages/LoadingPage";
import type Article from "models/Article";
import { type ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import articlesService from "services/articlesService";

function ModifyArticlePage(): ReactElement {
  const { articleId } = useParams();
  const [article, setArticle] = useState<Article>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      setIsLoading(true);
      const res = await articlesService.getById(articleId!);
      setArticle(res);
      setIsLoading(false);
    }

    if (articleId != null) {
      void fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }
  if (article == null || articleId == null) {
    return <NotFoundErrorPage />;
  }

  return <ArticleForm modification article={article} />;
}

export default ModifyArticlePage;
