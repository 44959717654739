import { Box, Typography, type TypographyProps, useTheme } from "@mui/material";
import BackButton from "components/atoms/buttons/BackButton";
import { type PropsWithChildren, type ReactElement } from "react";

interface PageTitleProps extends PropsWithChildren {
  noBackButton?: boolean;
  title: string | ReactElement;
  typographyProps?: TypographyProps;
}

function PageTitle({
  noBackButton = false,
  children,
  title,
  typographyProps,
}: PageTitleProps): ReactElement {
  const theme = useTheme();
  return (
    <Box bgcolor={theme.palette.grey[100]} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h2" {...typographyProps}>
        {!noBackButton && <BackButton />}
        {title}
      </Typography>
      {children}
    </Box>
  );
}

export default PageTitle;
