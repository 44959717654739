import { routesConfig } from "config/app-config";
import ConnectionPage from "pages/ConnectionPage";
import FlushPage from "pages/FlushPage";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "pages/errors/ErrorPage";
import Root from "components/templates/Root";
import DisclaimerPage from "pages/DisclaimerPage";

const unAuthenticatedRouter = createBrowserRouter([
  {
    element: <Root isUnauthenticated />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routesConfig.home.path,
        element: <ConnectionPage />,
      },
      {
        path: routesConfig.flush.path,
        element: <FlushPage />,
      },
      {
        path: routesConfig.disclaimer.path,
        element: <DisclaimerPage />,
      },
      {
        path: routesConfig.any.path,
        element: <ConnectionPage />,
      },
    ],
  },
]);

export default unAuthenticatedRouter;
