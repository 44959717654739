import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Grid } from "@mui/material";
import type { SxProps } from "@mui/material";
import type { User } from "@sdeapps/react-core";
import CommandeCardSkeleton from "components/molecules/cards/CommandeCard/CommandeCardSkeleton";
import type Commande from "models/Commande";
import LazyLoader from "utils/LazyLoader";
import CommandeListe from "./CommandeListe";

interface LazyLoadedSearchResultsProps {
  sx?: SxProps;
  initialResultsNumber?: number;
  step?: number;

  commandes: Array<Commande>;
  agents?: Array<User>;
  uri?: (id: string) => string;
  displayArticleListHeader?: boolean;
  displayCommentaires?: boolean;
  hasCommandeLink?: boolean;
}

const DEFAULT_NUMBER_OF_RESULTS_TO_DISPLAY = 30;
const INCREASE_STEP_NUMBER_OF_RESULTS_TO_DISPLAY = 20;

/**
 * Affiche l'interface (messages, CircularProgress) et les résultats de la recherche.
 * A entourer d'un SearchProvider.
 * @param sx?: SxProps
 */
function LazyLoadedCommandeList({
  sx,
  initialResultsNumber = DEFAULT_NUMBER_OF_RESULTS_TO_DISPLAY,
  step = INCREASE_STEP_NUMBER_OF_RESULTS_TO_DISPLAY,

  commandes,
  agents,
  uri,
  displayArticleListHeader = false,
  displayCommentaires = false,
  hasCommandeLink = false,
}: Readonly<LazyLoadedSearchResultsProps>): ReactElement {
  const [numberOfResultsToDisplay, setNumberOfResultsToDisplay] = useState(initialResultsNumber);

  useEffect(() => {
    setNumberOfResultsToDisplay(initialResultsNumber);
  }, [commandes, initialResultsNumber]);

  function increaseNumberOfResultsToDisplay(): void {
    const filteredLength: number = commandes.length;
    if (numberOfResultsToDisplay === commandes.length) {
      return;
    }

    const newNumberOfResultsToDisplay: number =
      numberOfResultsToDisplay + step > filteredLength
        ? filteredLength
        : numberOfResultsToDisplay + step;

    setNumberOfResultsToDisplay(newNumberOfResultsToDisplay);
  }

  return (
    <Grid item container xs={12} spacing={1} sx={{ position: "relative", ...sx }}>
      <CommandeListe
        item
        commandes={commandes.slice(0, numberOfResultsToDisplay)}
        agents={agents}
        hasCommandeLink={hasCommandeLink}
        uri={uri}
        displayArticleListHeader={displayArticleListHeader}
        displayCommentaires={displayCommentaires}
      />

      <LazyLoader lazyLoad={increaseNumberOfResultsToDisplay} />
      <Grid container item xs={12} spacing={4}>
        {commandes.length > numberOfResultsToDisplay &&
          [1, 2, 3, 4].map((k) => (
            <Grid item xs={12} lg={6} key={k}>
              <CommandeCardSkeleton />
            </Grid>
          ))}
      </Grid>
      <LazyLoader lazyLoad={increaseNumberOfResultsToDisplay} sx={{ bottom: "10vh" }} />
    </Grid>
  );
}

export default LazyLoadedCommandeList;
