import type Article from "models/Article";
import { type ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import articlesService from "services/articlesService";
import NotFoundErrorPage from "../errors/NotFoundErrorPage";
import ArticleInformationTemplate from "components/templates/ArticleInformationTemplate";
import ModalPageTemplate from "components/templates/ModalPageTemplate";
import ArticleInformationSkeleton from "components/templates/ArticleInformationSkeleton";

function ArticleInformationPage(): ReactElement {
  const { articleId } = useParams();
  const [article, setArticle] = useState<Article>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (articleId != null) {
        setIsLoading(true);
        setArticle(await articlesService.getById(articleId));
      }
      setIsLoading(false);
    }

    void fetchData();
  }, [articleId]);

  if (isLoading) {
    return (
      <ModalPageTemplate>
        <ArticleInformationSkeleton />
      </ModalPageTemplate>
    );
  }
  if (article == null || articleId == null) {
    return (
      <ModalPageTemplate>
        <NotFoundErrorPage />
      </ModalPageTemplate>
    );
  }
  return (
    <ModalPageTemplate>
      <ArticleInformationTemplate article={article} />
    </ModalPageTemplate>
  );
}

export default ArticleInformationPage;
