import { Grid, Typography, useTheme, useMediaQuery, Skeleton } from "@mui/material";
import { Container } from "@mui/system";
import PageTitleSkeleton from "components/molecules/PageTitleSkeleton";
import { type ReactElement } from "react";

function InformationsPratiquesSkeleton(): ReactElement {
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4} md={2}>
        <Skeleton animation="wave" />
      </Grid>
      <Grid item xs={12} md={10}>
        <Skeleton animation="wave" />
      </Grid>
    </Grid>
  );
}

function ArticleInformationSkeleton(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <PageTitleSkeleton />
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            container
            md={3}
            xs={12}
            spacing={2}
            alignContent="center"
            justifyContent="center">
            <Grid item sx={{ position: "relative" }} justifyContent="center" container>
              <Skeleton
                variant="circular"
                animation="wave"
                width={isSmallScreen ? 120 : 140}
                height={isSmallScreen ? 120 : 140}
              />
            </Grid>
          </Grid>
          <Grid item container md={9} xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Typography>
            </Grid>

            <Grid item container alignItems="center" justifyContent="flex-end" spacing={1}>
              <Grid item width="100px" xs={isSmallScreen || undefined}>
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid item width="160px" xs={isSmallScreen || undefined}>
                <Skeleton animation="wave" height={60} />
              </Grid>
              <Grid item width="200px" xs={isSmallScreen || undefined}>
                <Skeleton animation="wave" height={60} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">
              <Skeleton animation="wave" width={isSmallScreen ? "50%" : 300} />
            </Typography>
          </Grid>

          <InformationsPratiquesSkeleton />
          <InformationsPratiquesSkeleton />
          <InformationsPratiquesSkeleton />
        </Grid>
      </Container>
    </>
  );
}

export default ArticleInformationSkeleton;
