import {
  Autocomplete,
  type AutocompleteRenderGetTagProps,
  Chip,
  FormControl,
  TextField,
} from "@mui/material";
import type Article from "models/Article";
import { memo, type ReactElement } from "react";

type ArticleInputCommonProps = {
  availableArticles: Array<Article>;
};
type ArticleInputUniqueProps = {
  multiple?: never;
  unique: boolean;
  selectedArticles?: never;
  selectedArticle: Article | null;
  setSelectedArticles?: never;
  setSelectedArticle: (a: Article | null) => void;
};
type ArticleInputMultipleProps = {
  multiple: boolean;
  unique?: never;
  selectedArticles: Array<Article>;
  selectedArticle?: never;
  setSelectedArticles: (a: Array<Article>) => void;
  setSelectedArticle?: never;
};

type ArticleInputProps = ArticleInputCommonProps &
  (ArticleInputUniqueProps | ArticleInputMultipleProps);

function renderChipTags(
  value: Array<Article>,
  getTagProps: AutocompleteRenderGetTagProps
): Array<ReactElement> {
  return value.map((option, index) => (
    // eslint-disable-next-line react/jsx-key
    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
  ));
}

const ArticleInput = memo(function ArticleInput({
  selectedArticle,
  selectedArticles,
  setSelectedArticle,
  setSelectedArticles,
  availableArticles,
  multiple = false,
  unique = false,
}: ArticleInputProps): ReactElement {
  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple={multiple}
        options={availableArticles}
        onChange={(e, newValue) => {
          if (unique && setSelectedArticle != null) {
            setSelectedArticle(newValue as Article | null);
          }
          if (multiple && setSelectedArticles != null) {
            setSelectedArticles(newValue as Array<Article>);
          }
        }}
        value={selectedArticle ?? selectedArticles ?? null}
        renderInput={(params) => (
          <TextField {...params} label="Nom d'un article" placeholder="Nom d'un article" />
        )}
        renderTags={renderChipTags}
        limitTags={3}
        size="small"
      />
    </FormControl>
  );
});

export default ArticleInput;
