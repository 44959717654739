import { type ReactElement } from "react";
import {
  Grid,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import StatutChip from "components/atoms/Chips/StatutChip";
import TypographyLineThroughIfRefused from "components/atoms/TypographyLineThroughIfRefused";
import type CommandeArticle from "models/CommandeArticle";
import ArticleLineHeader from "./ArticleLineHeader";

type ArticleListProps = {
  articles: Array<CommandeArticle>;
  displayHeader?: boolean;
};

function ArticleList({ articles, displayHeader = false }: ArticleListProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const bodyVariant: { variant: "body1" | "body2" } = {
    variant: isSmallScreen ? "body2" : "body1",
  };

  const padding05: { sx: SxProps } = {
    sx: { paddingX: 0.5, paddingY: 1, verticalAlign: "top" },
  };

  return (
    <TableContainer sx={{ width: "100%" }} item component={Grid}>
      <Table size="small">
        {displayHeader && <ArticleLineHeader />}
        <TableBody>
          {articles.map((article) => (
            <TableRow
              key={article.commandeArticleNumber}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" {...padding05}>
                <TypographyLineThroughIfRefused
                  {...bodyVariant}
                  statut={article.statutArticle}
                  fontWeight="600">
                  {article.quantite}
                </TypographyLineThroughIfRefused>
              </TableCell>
              <TableCell {...padding05}>
                <TypographyLineThroughIfRefused {...bodyVariant} statut={article.statutArticle}>
                  {article.articleLabel}
                </TypographyLineThroughIfRefused>
              </TableCell>
              <TableCell {...padding05} align="right">
                <TypographyLineThroughIfRefused {...bodyVariant} statut={article.statutArticle}>
                  {article.taille === "Taille unique" ? "TU" : article.taille}
                </TypographyLineThroughIfRefused>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  width: { xs: "110px", md: "120px" },
                  verticalAlign: "top",
                  paddingX: 0.5,
                  paddingY: 1,
                  paddingRight: 0,
                }}>
                <StatutChip
                  statut={article.statutArticle}
                  sx={{ width: "100%" }}
                  textAlign="center"
                  isSmall
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ArticleList;
