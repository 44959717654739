import {
  Autocomplete,
  Badge,
  Box,
  Collapse,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import type Commande from "models/Commande";
import { type User, dateUtil } from "@sdeapps/react-core";
import { type ReactElement, memo, useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import Statut from "constants/Statut";
import StatutInput from "components/atoms/inputs/StatutInput";
import ArticleInput from "components/atoms/inputs/ArticleInput";
import type Article from "models/Article";
import MagasinDeRetraitInput from "components/atoms/inputs/MagasinDeRetraitInput";

type CommandesFilterProps = {
  commandes: Array<Commande>;
  setFilteredCommandes: (c: Array<Commande>) => void;

  agentFilter?: boolean;
  articleFilter?: boolean;
  statutFilter?: boolean;
  centreRetraitFilter?: boolean;

  availableAgents?: Array<User>;
  availableArticles?: Array<Article>;
  availableStatuts?: Array<Statut>;

  selectedAgentIdDefault?: string;
  selectedStatutsDefault?: Array<Statut>;
  selectedArticlesDefault?: Array<Article>;
  selectedCentresRetraitDefault?: Array<string>;
};

const CommandesFilter = memo(function CommandesFilter({
  commandes = [],
  setFilteredCommandes,

  agentFilter = false,
  articleFilter = false,
  statutFilter = false,
  centreRetraitFilter = false,

  availableAgents = [],
  availableArticles = [],
  availableStatuts = Object.values(Statut),

  selectedAgentIdDefault,
  selectedStatutsDefault = [],
  selectedArticlesDefault = [],
  selectedCentresRetraitDefault = [],
}: CommandesFilterProps): ReactElement {
  const [open, setOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<User | undefined>(
    availableAgents.find((a: User) => a.id === selectedAgentIdDefault)
  );
  const [selectedStatuts, setSelectedStatuts] = useState<Array<Statut>>(selectedStatutsDefault);
  const [selectedArticles, setSelectedArticles] = useState<Array<Article>>(selectedArticlesDefault);
  const [selectedCentresRetrait, setSelectedCentresRetrait] = useState<Array<string>>(
    selectedCentresRetraitDefault
  );
  const [nbrFilter, setNbrFilter] = useState<number>();
  const [filteredCommandesCount, setFilteredCommandesCount] = useState<number>(0);

  const filterCommandes = useCallback(() => {
    let filteredCommandes = [...commandes];
    if (selectedAgent != null) {
      filteredCommandes = filteredCommandes.filter(
        (commande: Commande) => commande.demandeurId === selectedAgent?.id
      );
    }
    if (selectedArticles.length > 0) {
      filteredCommandes = filteredCommandes.filter((commande: Commande) =>
        selectedArticles.some((article) =>
          commande.articles.map((a) => a.articleId).includes(article.id)
        )
      );
    }
    if (selectedStatuts.length > 0) {
      filteredCommandes = filteredCommandes.filter((commande: Commande) =>
        selectedStatuts.map((c) => c.toLowerCase()).includes(commande.statutCommande.toLowerCase())
      );
    }
    if (selectedCentresRetrait.length > 0) {
      filteredCommandes = filteredCommandes.filter((commande: Commande) =>
        selectedCentresRetrait
          .map((c) => c.toLowerCase())
          .includes(commande.magasinDeRetrait.toLowerCase())
      );
    }
    filteredCommandes.sort((a, b) => dateUtil.compareAsc(a.dateCommande, b.dateCommande));
    return filteredCommandes;
  }, [selectedStatuts, selectedAgent, selectedCentresRetrait, selectedArticles, commandes]);

  useEffect(() => {
    availableAgents.sort((a: User, b: User) => (a.displayName > b.displayName ? 1 : -1));
  }, [availableAgents]);

  useEffect(() => {
    availableArticles.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [availableArticles]);

  useEffect(() => {
    const commandes = filterCommandes();
    setFilteredCommandesCount(commandes.length);
    setFilteredCommandes(commandes);
    setNbrFilter(
      selectedStatuts.length +
        (selectedAgent != null ? 1 : 0) +
        selectedCentresRetrait.length +
        selectedArticles.length
    );
  }, [selectedStatuts, selectedAgent, selectedCentresRetrait, selectedArticles, commandes]);

  return (
    <Grid item container spacing={2}>
      <Grid item>
        <Badge color="error" badgeContent={nbrFilter}>
          <Button
            variant="contained"
            startIcon={<TuneIcon />}
            onClick={() => {
              setOpen(!open);
            }}>
            Filtres
          </Button>
        </Badge>
      </Grid>
      <Grid item container spacing={2} sx={{ margin: 0 }}>
        <Collapse in={open} unmountOnExit sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {agentFilter && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Nom d'un agent" placeholder="Nom d'un agent" />
                    )}
                    value={selectedAgent ?? null}
                    onChange={(e, newValue) => {
                      setSelectedAgent(newValue!);
                    }}
                    options={availableAgents}
                    renderOption={(props, option: User) => (
                      <Box component="li" {...props} key={option.id}>
                        {option.displayName}
                      </Box>
                    )}
                    getOptionLabel={(option: User) => option.displayName}
                    limitTags={3}
                  />
                </FormControl>
              </Grid>
            )}
            {articleFilter && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ArticleInput
                  multiple
                  availableArticles={availableArticles}
                  selectedArticles={selectedArticles}
                  setSelectedArticles={setSelectedArticles}
                />
              </Grid>
            )}
            {statutFilter && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <StatutInput
                  selectedStatuts={selectedStatuts}
                  setSelectedStatuts={setSelectedStatuts}
                  availableStatuts={availableStatuts}
                  label="État(s) de la commande"
                />
              </Grid>
            )}
            {centreRetraitFilter && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MagasinDeRetraitInput
                  multiple
                  label="Magasin de retrait"
                  selectedCentresRetrait={selectedCentresRetrait}
                  setSelectedCentresRetrait={setSelectedCentresRetrait}
                />
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        {filteredCommandesCount <= 0 ? (
          <Typography>Aucune commande à afficher</Typography>
        ) : (
          <Typography>{`${filteredCommandesCount} commande(s)`}</Typography>
        )}
      </Grid>
    </Grid>
  );
});

export default CommandesFilter;
