async function readFileAsDataURL(file: Blob): Promise<string | ArrayBuffer | null> {
  const resultBase64 = await new Promise<string | ArrayBuffer | null>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  });

  return resultBase64;
}

const fileUtil = {
  readFileAsDataURL,
};

export default fileUtil;
