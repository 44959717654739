import { apiConfig } from "config/app-config";
import type Article from "models/Article";
import networkService from "services/networkService";
import type CreateArticleModel from "models/CreateArticleModel";
import type PatchData from "models/PatchData";

const endpoint = "articles";
const apiUrl = `${apiConfig.apimBaseUrl}/${apiConfig.productsApi.apiName}/${endpoint}`;
const scope = apiConfig.productsApi.scopes;

async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scope);
}

/**
 * Récupère un produit en fonction de son identifiant unique
 * @param id l'identifiant unique
 * @returns un article
 */
async function getById(id: string): Promise<Article> {
  const url = `${apiUrl}/${id}`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Récupère tous les produits
 * @returns Liste des produits
 */
async function getAll(): Promise<Array<Article>> {
  const url = apiUrl;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Créée un nouvel article
 * @returns L'article créé
 */
async function create(articleToCreate: CreateArticleModel): Promise<Article> {
  const url = apiUrl;

  const options: RequestInit = {
    method: "POST",
    headers: await createHeaders(),
    mode: "cors",
    body: JSON.stringify(articleToCreate),
  };
  return fetch(url, options).then(async (res) => (await res.json())?.data);
}

/**
 * Patche un article
 * @param articleId l'id de l'article
 * @param patchData un tableau de PatchTRUC contenant les modifications
 * à apporter à l'article
 * @returns l'article modifié
 */
async function patch(
  articleId: string,
  // patchData: Array<PatchArticle>
  patchData: Array<PatchData>
): Promise<Article> {
  const url = `${apiUrl}/${articleId}`;
  const options: RequestInit = {
    method: "PATCH",
    headers: await createHeaders(),
    body: JSON.stringify(patchData),
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

const articlesService = {
  getAll,
  getById,
  create,
  patch,
};
export default articlesService;
