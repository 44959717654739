import { configureStore } from "@reduxjs/toolkit";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";
import cartReducer from "reducers/cartReducer";
import commandesReducer, { type CommandesReducerType } from "reducers/commandesReducer";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    commandes: commandesReducer,
  },
});

export type StoreProps = {
  cart: Array<CommandeArticleCartModel>;
  commandes: CommandesReducerType;
};

export default store;
