import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { type ReactElement } from "react";

function DeleteButton({ deleteImage }: { deleteImage: () => void }): ReactElement {
  return (
    <IconButton
      aria-label="delete"
      onClick={(e) => {
        e.stopPropagation();
        deleteImage();
      }}
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
      }}>
      <CancelIcon
        color="error"
        sx={{
          borderRadius: "100%",
          bgcolor: "white",
        }}
      />
    </IconButton>
  );
}

export default DeleteButton;
