import { type AuthenticationConfig } from "@sdeapps/react-core";

const APPLICATION_NAME = "Equipe-toi";
const DEFAULT_PAGE_TITLE = "Equipe-toi";

const config = {
  basename: import.meta.env.PUBLIC_PATH,
  isBrowser: typeof window !== "undefined",
  appName: APPLICATION_NAME,
  appVersion: import.meta.env.VITE_VERSION ?? "?",
};

type RoutesConfig = Record<
  string,
  {
    path: string;
    getParameterPath: (parameter: string) => string;
    title: string;
  }
>;

const routesConfig: RoutesConfig = {
  home: { path: "/", getParameterPath: (_) => "/", title: DEFAULT_PAGE_TITLE },
  catalog: { path: "/catalogue", getParameterPath: (_) => "/catalogue", title: "Catalogue" },
  catalogArticle: {
    path: "/catalogue/:articleId",
    getParameterPath: (articleId: string) => `/catalogue/${articleId}`,
    title: "Fiche de l'article",
  },
  userCommand: {
    path: "/mes-commandes",
    getParameterPath: (_) => "/mes-commandes",
    title: "Mes commandes",
  },
  cart: { path: "/panier", getParameterPath: (_) => "/panier", title: "Mon Panier" },
  flush: { path: "/flush", getParameterPath: (_) => "/flush", title: "Equipe-toi logout" },
  validator: {
    path: "/validateur",
    getParameterPath: (_) => "/validateur",
    title: "Chargé SST - Commandes à traiter",
  },
  validatorCommand: {
    path: "/validateur/commande/:commandeId",
    getParameterPath: (commandeId: string) => `/validateur/commande/${commandeId}`,
    title: "Chargé SST - Commande à traiter",
  },
  createArticle: {
    path: "/validateur/article/new",
    getParameterPath: (articleId: string) => `/validateur/article/${articleId}`,
    title: "Chargé SST - Création d'article",
  },
  modifyArticle: {
    path: "/validateur/article/:articleId",
    getParameterPath: (articleId: string) => `/validateur/article/${articleId}`,
    title: "Chargé SST - Modification d'article",
  },
  storekeeper: {
    path: "/magasinier",
    getParameterPath: (_) => "/magasinier",
    title: "Magasinier - Commandes à traiter",
  },
  storekeeperCommand: {
    path: "/magasinier/commande/:commandeId",
    getParameterPath: (commandeId: string) => `/magasinier/commande/${commandeId}`,
    title: "Magasinier - Commande à traiter",
  },
  storekeeperTriParArticle: {
    path: "/magasinier/a-preparer-par-article",
    getParameterPath: (_) => "/magasinier/a-preparer-par-article",
    title: "Magasinier - Tri par article",
  },
  disclaimer: {
    path: "/mentions-legales",
    getParameterPath: (_) => "/mentions-legales",
    title: "Mentions légales",
  },
  any: { path: "*", getParameterPath: (_) => "*", title: "Not Found" },
};

const authenticationConfig: AuthenticationConfig = {
  msal: {
    auth: {
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      authority: import.meta.env.VITE_AUTH_AUTHORITY,
      knownAuthorities: [import.meta.env.VITE_AUTH_AUTHORITY ?? ""],
      redirectUri: import.meta.env.VITE_APP_BASE_URL,
      postLogoutRedirectUri: import.meta.env.VITE_APP_BASE_URL,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  },
  graph: {
    graphEndpoint: "https://graph.microsoft.com/v1.0",
    scopes: ["User.Read"],
  },
};

const apiConfig = {
  apimBaseUrl: import.meta.env.VITE_APIM_BASE_URL ?? "",
  apimSubscriptionKey: import.meta.env.VITE_APIM_SUBSCRIPTION_KEY ?? "",
  azureAd: {
    scopes: ["User.Read", "User.ReadBasic.All", "User.Read.All"],
  },
  productsApi: {
    scopes: import.meta.env?.VITE_PRODUITS_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_PRODUCTS_API_NAME ?? "",
  },
  ordersApi: {
    scopes: import.meta.env?.VITE_ORDERS_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_ORDERS_API_NAME ?? "",
  },
};

const appiConfig: { instrumentationKey: string } = {
  instrumentationKey: import.meta.env.VITE_APPI_INSTRUMENTATION_KEY ?? "",
};

const envConfig: { name: string } = {
  name: import.meta.env.VITE_ENV ?? "",
};

const graphConfig: { graphEndpoint: string } = {
  graphEndpoint: "https://graph.microsoft.com/v1.0",
};

const localStorageKeys = {
  navigationMenu: "isNavigationMenuOpen",
};

export {
  config,
  routesConfig,
  DEFAULT_PAGE_TITLE,
  authenticationConfig,
  apiConfig,
  graphConfig,
  appiConfig,
  envConfig,
  localStorageKeys,
};
