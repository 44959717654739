import { apiConfig, graphConfig } from "config/app-config";
import { authenticationService, type User, arrayUtil } from "@sdeapps/react-core";

type Headers = {
  Accept: string;
  "Content-Type": string;
  Authorization: string;
  ConsistencyLevel: string;
};

/**
 * Génère les headers pour la requête http vers l'API graph
 * @returns Le header
 */
async function createHeaders(): Promise<Headers> {
  const accessToken = await authenticationService.getAccessToken(apiConfig.azureAd.scopes);

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    ConsistencyLevel: "eventual",
  };
}

/**
 * Fonction qui récupère l'image de profil d'un agent sdea
 * @param id est l'identifiant de l'agent
 * @returns l'image de profil au format blob
 */
async function getPhotoByUser(id: string): Promise<Blob> {
  const url = `${graphConfig.graphEndpoint}/users/${id}/photo/$value`;
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  return await fetch(url, options)
    .then(async (res) => res.blob())
    .catch((error: Error) => {
      console.error(error);
      return new Blob();
    });
}

/**
 * Récupère la totalité des agents
 * @param selectFilter les informations supplémentaires précises que
 * l'on souhaite récupérer sur l'agent
 * @returns
 */
async function getAllAgents(selectFilter: Array<string> = []): Promise<Array<User>> {
  const options = {
    method: "GET",
    headers: await createHeaders(),
  };

  let url = `${graphConfig.graphEndpoint}/users?$top=999`;
  let filters = ["surname", "givenName", "displayName", "id", "jobTitle", "employeeId"];
  if (selectFilter.length > 0) {
    filters = filters.concat(selectFilter);
  }
  url += "&$select=" + filters.toString();
  url += "&$filter=employeeId ne null&$count=true";

  return fetch(url, options)
    .then(async (res) => res.json())
    .then(async (res) => res.value)
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Récupère la totalité des agents
 * @param selectFilter les informations supplémentaires précises que
 * l'on souhaite récupérer sur l'agent
 * @returns
 */
async function getAgentsByIds(
  agentIds: Array<string>,
  selectFilter: Array<string> = []
): Promise<Array<User>> {
  const url = `${graphConfig.graphEndpoint}/$batch`;

  const uniqueAgentsIds = arrayUtil.uniqueInArray(agentIds);

  const requests: Array<{
    id: string;
    method: string;
    url: string;
    headers?: Record<string, unknown>;
  }> = [];

  let filters = ["surname", "givenName", "displayName", "id", "jobTitle", "employeeId", "mail"];
  if (selectFilter?.length > 0) {
    filters = filters.concat(selectFilter);
  }
  uniqueAgentsIds.forEach((agentId, i) => {
    let _url = `users/${agentId}`;
    _url += "?$select=" + filters.toString();
    requests.push({
      id: i.toString(),
      method: "GET",
      url: _url,
    });
  });

  const batchPromises: Array<Promise<any>> = [];

  /** Les requêtes batch de l'API graph acceptent max 20 requêtes à la fois, donc
   * on découpe les requêtes en paquets de 20. */
  const chunkSize = 20;
  for (let i = 0; i < requests.length; i += chunkSize) {
    const options = {
      method: "POST",
      headers: await createHeaders(),
      mode: "cors" as RequestMode,
      body: JSON.stringify({ requests: requests.slice(i, i + chunkSize) }),
    };

    batchPromises.push(fetch(url, options).then(async (res) => res.json()));
  }

  const users: Array<User> = [];
  try {
    const batchResponses = await Promise.all(batchPromises);
    batchResponses.forEach(
      ({ responses }: { responses: Array<{ body: User | undefined; status: number }> }) => {
        responses?.forEach((res) => {
          if (res?.body != null && res.status >= 200 && res.status < 400) {
            users.push(res.body);
          }
        });
      }
    );
  } catch (error) {
    console.error(error);
  }

  return users;
}

const graphService = {
  getPhotoByUser,
  getAllAgents,
  getAgentsByIds,
};

export default graphService;
