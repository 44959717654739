/* eslint-disable @typescript-eslint/dot-notation */
import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { type ReactElement, type ReactNode, useEffect, useState } from "react";
import articlesService from "services/articlesService";
import { typesTailles, allTaillesMap } from "constants/Tailles";
import LoadingButton from "components/atoms/buttons/LoadingButton";
import type CreateArticleModel from "models/CreateArticleModel";
import { type SnackbarKey, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import NumberInput from "components/atoms/inputs/NumberInput";
import type Article from "models/Article";
import type PatchData from "models/PatchData";
import PhotoInput from "components/atoms/inputs/PhotoInput";
import { routesConfig } from "config/app-config";
import PageTitle from "components/molecules/PageTitle";
import categories from "constants/Categories";

type CreationProps = {
  creation: boolean;
  modification?: never;
  article?: never;
};
type ModificationProps = {
  creation?: never;
  modification: boolean;
  article: Article;
};

type ArticleFormProps = CreationProps | ModificationProps;

function ArticleForm({ creation, modification, article }: ArticleFormProps): ReactElement {
  function isValueIfModificationElseDefault<T>(value: T | undefined, defaultValue: T): T {
    let newValue: T | undefined;
    if (modification != null && modification && value != null) {
      newValue = value;
    }
    return newValue ?? defaultValue;
  }

  const [selectedLabel, setSelectedLabel] = useState<string>(
    isValueIfModificationElseDefault(article?.label, "")
  );
  const [selectedCategorie, setSelectedCategorie] = useState<string>(
    isValueIfModificationElseDefault(article?.categorie, "")
  );
  const [selectedUniteMesureTaille, setSelectedUniteMesureTaille] = useState<string>(
    isValueIfModificationElseDefault(article?.uniteMesureTaille, "")
  );
  const [selectedMinimum, setSelectedMinimum] = useState<string>(
    isValueIfModificationElseDefault(article?.tailles[0], "")
  );
  const [selectedMaximum, setSelectedMaximum] = useState<string>(
    isValueIfModificationElseDefault(article?.tailles[article?.tailles.length - 1], "")
  );
  const [selectedDescription, setSelectedDescription] = useState<string>(
    isValueIfModificationElseDefault(article?.description, "")
  );
  const [quantiteMax, setQuantiteMax] = useState(
    isValueIfModificationElseDefault(article?.quantiteMax, 1)
  );

  const [protection, setProtection] = useState(
    isValueIfModificationElseDefault(article?.informations["Protection"], "")
  );
  const [habilitation, setHabilitation] = useState(
    isValueIfModificationElseDefault(article?.informations["Habilitation"], "")
  );
  const [dureeDeVie, setDureeDeVie] = useState(
    isValueIfModificationElseDefault(article?.informations["Durée de vie"], "")
  );
  const [composition, setComposition] = useState(
    isValueIfModificationElseDefault(article?.informations["Composition"], "")
  );
  const [entretien, setEntretien] = useState(
    isValueIfModificationElseDefault(article?.informations["Entretien"], "")
  );
  const [caracteristiques, setCaracteristiques] = useState(
    isValueIfModificationElseDefault(article?.informations["Caractéristiques"], "")
  );

  const [imageBase64Data, setImageBase64Data] = useState("");
  const [imageIsUriRemoved, setImageIsUriRemoved] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const TAILLE_UNIQUE_STRING = "Taille unique";
  const isTailleUnique = selectedUniteMesureTaille === TAILLE_UNIQUE_STRING;

  function closeSnackbarAction(snackbarId: SnackbarKey): ReactNode {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}>
        <CloseIcon />
      </IconButton>
    );
  }

  useEffect(() => {
    if (isInitialized || (creation != null && creation)) {
      setSelectedMinimum("");
      setSelectedMaximum("");
    }
    if (!isInitialized) setIsInitialized(true);
  }, [creation, isInitialized, selectedUniteMesureTaille]);

  function isMaxError(): boolean {
    const currentTailles = allTaillesMap.get(selectedUniteMesureTaille);
    if (
      selectedMinimum !== "" &&
      selectedMaximum !== "" &&
      !isTailleUnique &&
      currentTailles != null
    ) {
      return (
        (currentTailles.indexOf(selectedMinimum) ?? 0) >
        (currentTailles.indexOf(selectedMaximum) ?? 0)
      );
    }
    return false;
  }

  function isFormReadyToSend(): boolean {
    return (
      !isMaxError() &&
      selectedLabel !== "" &&
      selectedCategorie !== "" &&
      selectedUniteMesureTaille !== "" &&
      (isTailleUnique || (selectedMinimum !== "" && selectedMaximum !== ""))
    );
  }

  function getCurrentSelectedTaillesSubArray(): Array<string> {
    if (isTailleUnique) {
      return [TAILLE_UNIQUE_STRING];
    }

    const currentTailles = allTaillesMap.get(selectedUniteMesureTaille);
    if (currentTailles == null) {
      throw Error("mauvaises tailles");
    }

    const indexMin = currentTailles.indexOf(selectedMinimum);
    const indexMax = currentTailles.indexOf(selectedMaximum) + 1;
    const subTailles = currentTailles.slice(indexMin, indexMax);

    return subTailles;
  }

  function onImageBase64Removed(): void {
    setImageBase64Data("");
  }

  function onImageNewBase64(base64: string): void {
    setImageBase64Data(base64);
  }

  function onImageUriRemoved(): void {
    setImageIsUriRemoved(true);
  }

  async function sendNewArticle(): Promise<void> {
    setIsLoading(true);
    try {
      const articleToCreate: CreateArticleModel = {
        label: selectedLabel,
        categorie: selectedCategorie,
        uniteMesureTaille: selectedUniteMesureTaille,
        tailles: getCurrentSelectedTaillesSubArray(),
        description: selectedDescription,
        base64Photo: imageBase64Data ?? "",
        quantiteMax,
        informations: {},
      };

      const informationsAndLabels: Record<string, string> = {
        Protection: protection,
        Habilitation: habilitation,
        "Durée de vie": dureeDeVie,
        Composition: composition,
        Entretien: entretien,
        Caractéristiques: caracteristiques,
      };

      for (const key in informationsAndLabels) {
        if (informationsAndLabels[key] != null && informationsAndLabels[key] !== "") {
          articleToCreate.informations[key] = informationsAndLabels[key];
        }
      }

      const createdArticle = await articlesService.create(articleToCreate);
      if (createdArticle?.id != null) {
        enqueueSnackbar("Le nouvel article a bien été créé", {
          action: closeSnackbarAction,
          variant: "success",
        });
        navigate(routesConfig.catalogArticle.getParameterPath(createdArticle.id));
      } else {
        throw Error("error creating article ! response is undefined");
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Une erreur est survenue lors de la création de l'article", {
        action: closeSnackbarAction,
        variant: "error",
      });
    }

    setIsLoading(false);
  }

  async function sendModifiedArticle(): Promise<void> {
    if (article == null) {
      return;
    }
    setIsLoading(true);
    try {
      const stringPropertiesToCheck: Record<
        string,
        string | number | Array<string> | Record<string, string>
      > = {
        label: selectedLabel,
        categorie: selectedCategorie,
        uniteMesureTaille: selectedUniteMesureTaille,
        description: selectedDescription,
        quantiteMax,
      };

      const patchData: Array<PatchData> = [];

      // #region Primitive type properties
      for (const key in stringPropertiesToCheck) {
        if (
          Object.prototype.hasOwnProperty.call(stringPropertiesToCheck, key) &&
          Object.prototype.hasOwnProperty.call(article, key)
        ) {
          if (article[key as keyof Article] !== stringPropertiesToCheck[key]) {
            patchData.push({
              op: "replace",
              path: `${key}`,
              value: stringPropertiesToCheck[key],
            });
          }
        }
      }
      // #endregion

      const taillesPatchData = getTaillesPatchData();
      if (taillesPatchData != null) {
        patchData.push(taillesPatchData);
      }

      const informationsPatchData = getInformationsPatchData();
      if (informationsPatchData != null) {
        patchData.push(informationsPatchData);
      }

      const photoPatchData = getPhotoPatchData();
      if (photoPatchData != null) {
        patchData.push(photoPatchData);
      }

      if (patchData.length !== 0) {
        await articlesService.patch(article.id, patchData);

        enqueueSnackbar("L'article a bien été modifié", {
          action: closeSnackbarAction,
          variant: "success",
        });
        navigate(routesConfig.catalogArticle.getParameterPath(article.id));
      } else {
        enqueueSnackbar("Aucune modification détectée !", {
          action: closeSnackbarAction,
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Une erreur est survenue lors de la modification de l'article", {
        action: closeSnackbarAction,
        variant: "error",
      });
    }

    setIsLoading(false);
  }

  function getTaillesPatchData(): PatchData | undefined {
    const tempTailles = getCurrentSelectedTaillesSubArray();
    if (
      (article?.tailles.length === tempTailles.length &&
        tempTailles.some((v, i) => v !== article.tailles[i])) ||
      article?.tailles.length !== tempTailles.length
    ) {
      return {
        op: "replace",
        path: "tailles",
        value: tempTailles,
      };
    }
  }

  function getInformationsPatchData(): PatchData | undefined {
    if (article == null) return;

    const tempInformations: Record<string, string> = {};
    const informationsAndLabels: Record<string, string> = {
      Protection: protection,
      Habilitation: habilitation,
      "Durée de vie": dureeDeVie,
      Composition: composition,
      Entretien: entretien,
      Caractéristiques: caracteristiques,
    };

    for (const key in informationsAndLabels) {
      if (informationsAndLabels[key] != null && informationsAndLabels[key] !== "") {
        tempInformations[key] = informationsAndLabels[key];
      }
    }

    if (
      (Object.keys(tempInformations).length === Object.keys(article.informations).length &&
        Object.keys(tempInformations).some(
          (v) => tempInformations[v] !== article.informations[v]
        )) ||
      Object.keys(tempInformations).length !== Object.keys(article.informations).length
    ) {
      return {
        op: "replace",
        path: "informations",
        value: tempInformations,
      };
    }
  }

  function getPhotoPatchData(): PatchData | undefined {
    if (article?.photo.sasUri != null && article.photo.sasUri !== "") {
      // Si on avait une ancienne photo et qu'il y en a une nouvelle
      if (imageBase64Data != null && imageBase64Data !== "") {
        return {
          op: "replace",
          path: "/Photo",
          value: {
            Base64: imageBase64Data,
          },
        };
      } else {
        // Si on avait une ancienne photo et qu'on la enlevée,
        // et qu'il n'y en a pas de nouvelle
        if (imageIsUriRemoved) {
          return {
            op: "remove",
            path: "/Photo",
          };
        }
      }
    } else {
      // Si on n'avait pas d'ancienne photo, et qu'on en a une nouvelle
      if (imageBase64Data != null && imageBase64Data !== "") {
        return {
          op: "add",
          path: "/Photo/Base64",
          value: imageBase64Data,
        };
      }
    }
  }

  return (
    <>
      <PageTitle
        title={
          modification != null && modification ? "Modification article" : "Ajout nouvel article"
        }
      />
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center" columnSpacing={4}>
          <Grid item container xs={12}>
            <TextField
              fullWidth
              label="Titre de l'article"
              required
              value={selectedLabel}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setSelectedLabel(e.target.value);
              }}
            />
          </Grid>

          <Grid item container xs={12} sm={5} justifyContent="center">
            <Grid item container width={200}>
              <PhotoInput
                defaultImageUri={
                  modification != null && modification ? article?.photo.sasUri : undefined
                }
                onBase64Removed={onImageBase64Removed}
                onNewBase64={onImageNewBase64}
                onUriRemoved={onImageUriRemoved}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={7} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Catégorie</InputLabel>
                <Select
                  value={selectedCategorie}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setSelectedCategorie(e.target.value);
                  }}
                  label="Catégorie">
                  {categories.map((categorie) => (
                    <MenuItem key={categorie.dataBaseLabel} value={categorie.dataBaseLabel}>
                      {categorie.dataBaseLabel}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Type de taille</InputLabel>
                <Select
                  value={selectedUniteMesureTaille}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setSelectedUniteMesureTaille(e.target.value);
                  }}
                  label="Type de taille">
                  {Object.keys(typesTailles).map((taille) => (
                    <MenuItem key={taille} value={taille}>
                      {taille}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required={!isTailleUnique}
                disabled={isTailleUnique || selectedUniteMesureTaille === ""}
                error={isMaxError()}>
                <InputLabel>Taille Min</InputLabel>
                <Select
                  value={selectedMinimum}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setSelectedMinimum(e.target.value);
                  }}
                  label="Taille Min">
                  {allTaillesMap.get(selectedUniteMesureTaille)?.map((taille) => (
                    <MenuItem key={taille} value={taille}>
                      {taille}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required={!isTailleUnique}
                disabled={isTailleUnique || selectedUniteMesureTaille === ""}
                error={isMaxError()}>
                <InputLabel>Taille Max</InputLabel>
                <Select
                  value={selectedMaximum}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setSelectedMaximum(e.target.value);
                  }}
                  label="Taille Max">
                  {allTaillesMap.get(selectedUniteMesureTaille)?.map((taille) => (
                    <MenuItem key={taille} value={taille}>
                      {taille}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {isMaxError() && (
              <Grid item xs={12} sx={{ paddingTop: 0 }}>
                <FormHelperText error>
                  La taille maximale doit être supérieure à la taille minimale
                </FormHelperText>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Quantité maximale autorisée dans une commande</Typography>
          </Grid>
          <Grid item xs={12}>
            <NumberInput value={quantiteMax} onChange={setQuantiteMax} minValue={1} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Description</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Description"
              value={selectedDescription}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setSelectedDescription(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary">
              Informations pratiques
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Protection</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Protection"
              value={protection}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setProtection(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Habilitation</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Habilitation"
              value={habilitation}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setHabilitation(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Durée de vie</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Durée de vie"
              value={dureeDeVie}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setDureeDeVie(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Composition</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Composition"
              value={composition}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setComposition(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Entretien</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Entretien"
              value={entretien}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setEntretien(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Caractéristiques</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              label="Caractéristiques"
              value={caracteristiques}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setCaracteristiques(e.target.value);
              }}
            />
          </Grid>

          <Grid item container xs={12}>
            <Grid item container xs={12} justifyContent="flex-end">
              {creation != null && creation && (
                <LoadingButton
                  disabled={!isFormReadyToSend()}
                  onClick={() => {
                    void sendNewArticle();
                  }}
                  loading={isLoading}>
                  Créer un nouvel article
                </LoadingButton>
              )}
              {modification != null && modification && (
                <LoadingButton
                  disabled={!isFormReadyToSend()}
                  onClick={() => {
                    void sendModifiedArticle();
                  }}
                  loading={isLoading}>
                  Modifier l'article
                </LoadingButton>
              )}
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <FormHelperText>
                {creation != null &&
                  creation &&
                  "Tous les champs obligatoires (*) doivent être remplis pour créer un nouvel article."}
                {modification != null &&
                  modification &&
                  "Tous les champs obligatoires (*) doivent être remplis pour modifier un article."}
              </FormHelperText>
            </Grid>
          </Grid>

          {modification != null && modification && (
            <Grid item container xs={12}>
              <Grid item container xs={12} justifyContent="flex-end"></Grid>
              <Grid item container xs={12} justifyContent="flex-end">
                <FormHelperText></FormHelperText>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default ArticleForm;
