import { createSvgIcon } from "@mui/material";

const TeteIcon = createSvgIcon(
  <svg viewBox="0 0 84 84">
    <path
      fill="currentColor"
      d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
    />
    <path
      d="M42 5.25C21.7 5.25 5.25 21.7 5.25 42C5.25 62.3 21.7 78.75 42 78.75C62.3 78.75 78.75 62.3 78.75 42C78.75 21.7 62.3 5.25 42 5.25ZM65.15 50.41C64.06 50.41 56.64 52.77 42 52.77C27.36 52.77 19.94 50.41 18.85 50.41C14.11 50.41 11.77 49.53 16.85 45.3C18.25 38.88 21.34 33.73 25.77 30.2C25.3 29.32 25.6 28.22 26.47 27.72L29.69 25.87C30.58 25.36 31.71 25.66 32.22 26.55L32.25 26.59C34.13 25.9 36.15 25.42 38.29 25.16V24.92C38.29 23.89 39.12 23.06 40.15 23.06H43.87C44.89 23.06 45.72 23.89 45.72 24.92V25.16C47.86 25.42 49.88 25.9 51.76 26.59L51.79 26.55C52.3 25.66 53.44 25.36 54.32 25.87L57.54 27.72C58.41 28.23 58.71 29.32 58.24 30.2C62.66 33.73 65.76 38.88 67.16 45.3C72.25 49.53 69.91 50.41 65.16 50.41H65.15Z"
      fill="white"
    />
  </svg>,
  "TeteIcon"
);
export default TeteIcon;
