import { Typography, useTheme, type TypographyProps } from "@mui/material";
import Statut from "constants/Statut";
import { type ReactElement } from "react";

interface TypographyLineThroughIfRefusedProps extends TypographyProps {
  statut: Statut;
}

function TypographyLineThroughIfRefused({
  statut,
  children,
  ...props
}: TypographyLineThroughIfRefusedProps): ReactElement {
  const theme = useTheme();

  return (
    <Typography
      {...props}
      sx={
        statut === Statut.REFUSE
          ? { textDecoration: "line-through", color: theme.palette.grey[500] }
          : undefined
      }>
      {children}
    </Typography>
  );
}

export default TypographyLineThroughIfRefused;
