import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import ApplicationRoles from "constants/ApplicationRoles";
import type Commande from "models/Commande";
import type PatchData from "models/PatchData";
import { Authorization } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import { useNavigate, useParams } from "react-router-dom";
import graphService from "services/graphService";
import commandesService from "services/commandesService";
import type Article from "models/Article";
import articlesService from "services/articlesService";
import NotFoundErrorPage from "pages/errors/NotFoundErrorPage";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import ModalPageTemplate from "components/templates/ModalPageTemplate";
import ValidatorCommandeTemplate from "components/templates/ValidatorCommandeTemplate";
import { useDispatch } from "react-redux";
import type store from "store/store";
import CommandePageTemplateSkeleton from "components/templates/CommandePageTemplateSkeleton";
import { getAllCommandes, setLoadingCommandsIds } from "reducers/commandesReducer";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";

/**
 * Page réservée aux Validateurs, listant les commandes en attente de validation
 */
function ValidatorCommandePage(): ReactElement {
  const { commandeId } = useParams();
  const [commande, setCommande] = useState<Commande>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [commentaire, setCommentaire] = useState<string>("");
  const [historyCommandes, setHistoryCommandes] = useState<Array<CommandeArticleHistory>>([]);
  const [articlesList, setArticlesList] = useState<Array<CommandeArticleFormModel>>([]);
  const [articles, setArticles] = useState<Array<Article>>([]);
  const [demandeur, setDemandeur] = useState<User | undefined>();

  const navigate = useNavigate();
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    async function getAgent(agentId: string): Promise<void> {
      const agents = await graphService.getAgentsByIds([agentId]);
      setDemandeur(agents.find((u) => u.id === agentId));
    }

    async function getCommande(): Promise<void> {
      if (commandeId == null) {
        // TODO meilleure gestion des erreurs
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return new Promise(() => {});
      }
      const fetchedCommande: Commande = await commandesService.getById(commandeId);
      fetchedCommande.articles.sort((a, b) => {
        return a.commandeArticleNumber - b.commandeArticleNumber;
      });

      void getAgent(fetchedCommande.demandeurId);
      const allArticles = await articlesService.getAll();
      setArticles(allArticles);

      const commands = await commandesService.getCommandeHistory(
        fetchedCommande.demandeurId,
        fetchedCommande.commandeId
      );

      setCommande(fetchedCommande);

      setArticlesList([...fetchedCommande.articles]);

      const history: Array<CommandeArticleHistory> = [];
      commands.forEach((value) => {
        const a = allArticles.find((a1) => value.articleId === a1.id);
        if (a != null) history.push({ categorie: a.categorie!, ...value });
      });

      setHistoryCommandes(history);

      setIsLoading(false);
    }

    void getCommande();
  }, []);

  async function updateCommande(): Promise<void> {
    if (commandeId == null) {
      // TODO meilleure gestion des erreurs
      return;
    }
    setIsSending(true);
    dispatch(setLoadingCommandsIds(commandeId));

    const statutsPatches: Array<PatchData> = articlesList.map((value, index) => {
      return {
        op: "replace",
        path: `/articles/${index}/statutArticle`,
        value: value.statutArticle!,
      };
    });

    const quantitePatches: Array<PatchData> = [];
    articlesList.forEach((element, index) => {
      if (element.quantite !== commande?.articles[index].quantite) {
        quantitePatches.push({
          op: "replace",
          path: `/articles/${index}/quantite`,
          value: element.quantite.toString(),
        });
      }
    });

    const taillePatches: Array<PatchData> = [];
    articlesList.forEach((element, index) => {
      if (element.taille !== commande?.articles[index].taille) {
        taillePatches.push({
          op: "replace",
          path: `/articles/${index}/taille`,
          value: element.taille.toString(),
        });
      }
    });

    const allPatches: Array<PatchData> = statutsPatches
      .concat(quantitePatches)
      .concat(taillePatches);

    try {
      if (statutsPatches != null) {
        if (commentaire.trim().length !== 0) {
          await commandesService.addComment(commandeId, commentaire);
        }

        await commandesService.patchCommande(commandeId, allPatches);
      } else throw new Error();
      // TODO error handling
    } catch (error) {
      // TODO error handling
      console.error(error);
    } finally {
      setIsSending(false);
      void dispatch(getAllCommandes());
    }
    navigate(-1);
  }

  if (isLoading) {
    return (
      <ModalPageTemplate>
        <CommandePageTemplateSkeleton />
      </ModalPageTemplate>
    );
  }
  if (commande == null || commandeId == null) {
    return (
      <ModalPageTemplate>
        <NotFoundErrorPage />
      </ModalPageTemplate>
    );
  }

  return (
    <ModalPageTemplate>
      <Authorization roles={ApplicationRoles.SSTMANAGER}>
        <ValidatorCommandeTemplate
          demandeur={demandeur}
          articlesList={articlesList}
          updateCommande={updateCommande}
          isSending={isSending}
          historyCommandes={historyCommandes}
          setArticlesList={setArticlesList}
          articles={articles}
          commentaire={commentaire}
          setCommentaire={setCommentaire}
          commande={commande}
        />
      </Authorization>
    </ModalPageTemplate>
  );
}

export default ValidatorCommandePage;
