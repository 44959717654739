import { IconButton, type IconButtonProps } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { type ReactElement } from "react";

interface BackButtonProps extends IconButtonProps {
  toHomePage?: boolean;
  fontSize?: "inherit" | "small" | "medium" | "large";
}
/**
 * Un Bouton qui permet de revenir en arrière (utilise le comportement natif du navigateur)
 * @param disabled Booléen qui détermine si le bouton est désactivé; par défaut, false
 * @param toHomePage Booléen qui détermine si le bouton renvoie à la page d'accueil; par défaut, false
 */
function BackButton({
  disabled = false,
  toHomePage = false,
  fontSize = "medium",
}: BackButtonProps): ReactElement {
  const navigate = useNavigate();
  return (
    <IconButton
      onClick={() => {
        if (toHomePage) {
          navigate("/");
        } else {
          navigate(-1);
        }
      }}
      disabled={disabled}>
      <ChevronLeftIcon fontSize={fontSize} sx={{ visibility: disabled ? "hidden" : "visible" }} />
    </IconButton>
  );
}

export default BackButton;
