import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import type Article from "models/Article";
import { type ReactElement, type ReactNode, useState } from "react";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";
import { useDispatch } from "react-redux";
import { type SnackbarKey, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { addArticle } from "reducers/cartReducer";

interface CartActionsProps {
  article: Article;
  justifyContent?: "center" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right";
  alignContent?: "center" | "start" | "end" | "flex-start" | "flex-end" | "normal";
  item?: boolean;
}

function CartActions({
  article,
  justifyContent,
  item,
  alignContent,
}: CartActionsProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const [quantite, setQuantite] = useState<number>(0);
  const [taille, setTaille] = useState<string>(initTaille);
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function initTaille(): string {
    return article.uniteMesureTaille === "Taille unique" ? "Taille unique" : "";
  }

  function closeSnackbarAction(snackbarId: SnackbarKey): ReactNode {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}>
        <CloseIcon />
      </IconButton>
    );
  }

  function addArticleToCart(): void {
    const commandArticle: CommandeArticleCartModel = {
      quantite,
      taille,
      articleLabel: article.label,
      articleId: article.id,
      quantiteMax: article.quantiteMax,
    };
    dispatch(addArticle(commandArticle));

    enqueueSnackbar("L'article a bien été ajouté au panier", {
      action: closeSnackbarAction,
      variant: "success",
    });

    setTaille(initTaille);
    setQuantite(0);
  }

  return (
    <Grid
      item={item}
      container
      alignItems="center"
      justifyContent={justifyContent}
      alignContent={alignContent}
      spacing={1}>
      <Grid item xs={isSmallScreen || undefined} sx={{ minWidth: "100px" }}>
        <FormControl fullWidth size="small">
          <InputLabel>Quantité</InputLabel>
          <Select
            size="small"
            label="Quantité"
            value={quantite}
            fullWidth={isSmallScreen}
            onChange={(event) => {
              setQuantite(event.target.value as number);
            }}>
            {Array.from(Array(article.quantiteMax + 1).keys()).map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item minWidth="160px" xs={isSmallScreen || undefined}>
        <FormControl fullWidth size="small">
          <InputLabel>Taille</InputLabel>
          <Select
            label="Taille"
            value={taille}
            onChange={(event) => {
              setTaille(event.target.value);
            }}>
            {article.tailles?.map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={isSmallScreen || undefined}>
        <Button
          disabled={quantite === 0 || taille === ""}
          variant="contained"
          onClick={addArticleToCart}
          endIcon={<AddShoppingCartIcon />}
          fullWidth>
          Ajouter
        </Button>
      </Grid>
    </Grid>
  );
}

export default CartActions;
