import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { type Categorie } from "constants/Categories";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import { type ReactElement, useEffect, useState } from "react";
import CommandeHistoryArticle from "./CommandeHistoryArticle";

interface CommandeHistoryCategorieProps {
  categorie: Categorie;
  history: Array<CommandeArticleHistory>;
}

function CommandeHistoryCategorie({
  categorie,
  history,
}: CommandeHistoryCategorieProps): ReactElement {
  const [open, setOpen] = useState(false);
  const [articleIds, setArticleIds] = useState<Array<string>>([]);
  const [articleQuantity, setArticleQuantity] = useState(0);

  useEffect(() => {
    let qte = 0;
    const tempArticleIds = history.map((value) => {
      qte += value.quantite;
      return value.articleId;
    });

    setArticleQuantity(qte);
    setArticleIds(Array.from(new Set(tempArticleIds)));
  }, [history]);

  function handleClick(): void {
    setOpen(!open);
  }

  return history.length > 0 ? (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <categorie.icon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography>
              {categorie.printLabel} (<strong>{articleQuantity}</strong>)
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {articleIds.map((value) => {
            const list: Array<CommandeArticleHistory> =
              history.filter((c) => c.articleId === value) ?? [];
            return (
              <CommandeHistoryArticle key={value} article={list[0].articleLabel} history={list} />
            );
          })}
        </List>
      </Collapse>
    </>
  ) : (
    <></>
  );
}

export default CommandeHistoryCategorie;
