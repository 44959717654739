import { createSvgIcon } from "@mui/material";

const RespiratoireIcon = createSvgIcon(
  <svg viewBox="0 0 84 84">
    <path
      fill="currentColor"
      d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
    />
    <path
      d="M42 5.25C21.7 5.25 5.25 21.7 5.25 42C5.25 62.3 21.7 78.75 42 78.75C62.3 78.75 78.75 62.3 78.75 42C78.75 21.7 62.3 5.25 42 5.25ZM16.33 33.68C16.33 30.75 17.04 29.06 18.49 28.52C21.72 27.32 27.03 32.84 32.72 39.37C32.35 39.78 31.99 40.21 31.65 40.66C28.22 36.7 21.51 29.17 19.07 30.07C18.37 30.33 17.99 31.61 17.99 33.68C17.99 41.57 23.52 48.22 28.43 52.17C28.61 53.1 28.87 54 29.24 54.84C23.5 50.83 16.33 42.97 16.33 33.68V33.68ZM42 60.94C35.29 60.94 29.85 56.48 29.85 49.76C29.85 45.64 32.59 41.35 35.84 38.65C37.88 36.95 38.76 34.51 42 34.51C45.24 34.51 46.12 36.95 48.17 38.65C51.41 41.35 54.15 45.65 54.15 49.76C54.15 56.47 48.71 60.94 42 60.94V60.94ZM54.76 54.84C55.13 54 55.4 53.1 55.57 52.17C60.48 48.22 66.01 41.58 66.01 33.68C66.01 31.61 65.63 30.33 64.93 30.07C62.5 29.16 55.79 36.7 52.35 40.66C52 40.21 51.64 39.78 51.28 39.37C56.97 32.84 62.27 27.31 65.51 28.52C66.96 29.06 67.67 30.75 67.67 33.68C67.67 42.96 60.5 50.83 54.76 54.84V54.84ZM47.79 41.96C46.96 41.96 45.31 38.65 41.99 38.65C38.67 38.65 37.02 41.96 36.19 41.96C35.66 41.96 35.36 42.33 35.36 42.79C35.36 43.25 35.73 43.62 36.19 43.62C37.02 43.62 40.33 40.31 41.99 40.31C43.65 40.31 46.96 43.62 47.79 43.62C48.3 43.62 48.62 43.25 48.62 42.79C48.62 42.33 48.25 41.96 47.79 41.96Z"
      fill="white"
    />
  </svg>,
  "RespiratoireIcon"
);
export default RespiratoireIcon;
