import { Divider, Grid, Typography } from "@mui/material";
import StatutButtonGroup from "components/atoms/buttons/StatutButtonGroup/StatutButtonGroup";
import { magasinierArticleStatutOptions } from "constants/SatutMap";
import Statut from "constants/Statut";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";
import { type ReactElement } from "react";

type ArticleListProps = {
  articlesList: Array<CommandeArticleFormModel>;
  setArticlesList: (c: Array<CommandeArticleFormModel>) => void;
};

function ArticleListStore({ articlesList, setArticlesList }: ArticleListProps): ReactElement {
  function updateStatut(newStatut: Statut, articleIndex: number): void {
    const tempArticleList = [...articlesList];
    tempArticleList[articleIndex].statutArticle = newStatut;
    setArticlesList(tempArticleList);
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {articlesList.map((article, i) => {
        return (
          article.statutArticle !== Statut.REFUSE && (
            <Grid item container spacing={2} alignItems="center" key={article.articleId} xs={12}>
              <Grid item xs={12} lg>
                <Typography>{article.articleLabel}</Typography>
              </Grid>
              <Grid item xs="auto" lg={1}>
                <Typography>
                  <strong>Qté</strong> : {article.quantite}
                </Typography>
              </Grid>
              <Grid item xs lg={2}>
                <Typography>
                  <strong>Taille</strong> : {article.taille}
                </Typography>
              </Grid>
              <Grid item container xs={12} md="auto" justifyContent="flex-end">
                <StatutButtonGroup
                  onChangeStatut={(newStatut?: Statut) => {
                    if (newStatut != null) {
                      updateStatut(newStatut, i);
                    }
                  }}
                  value={article.statutArticle}
                  statutOptions={magasinierArticleStatutOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          )
        );
      })}
    </Grid>
  );
}

export default ArticleListStore;
