import { createSvgIcon } from "@mui/material";

const YeuxIcon = createSvgIcon(
  <svg viewBox="0 0 84 84">
    <path
      fill="currentColor"
      d="M84 42C84 65.2 65.19 84 42 84C18.81 84 0 65.2 0 42C0 18.8 18.8 0 42 0C65.2 0 84 18.8 84 42Z"
    />
    <path
      d="M42 5.25C21.71 5.25 5.25 21.71 5.25 42C5.25 62.29 21.71 78.75 42 78.75C62.29 78.75 78.75 62.3 78.75 42C78.75 21.7 62.3 5.25 42 5.25ZM67.67 42C67.67 42.92 66.93 43.66 66.01 43.66H65.07C64.25 50.19 58.68 55.25 51.93 55.25C48.93 55.25 47.51 54.64 45.31 53.59C44.76 53.33 43.66 51.93 42 52.01C40.34 51.93 39.24 53.33 38.69 53.59C36.49 54.63 35.06 55.25 32.07 55.25C25.32 55.25 19.75 50.18 18.94 43.66H18C17.08 43.66 16.34 42.92 16.34 42V35.38C16.34 34.46 17.08 33.72 18 33.72H18.83V32.06C18.83 30.23 20.32 28.75 22.14 28.75H61.87C63.7 28.75 65.18 30.23 65.18 32.06V33.72H66.01C66.92 33.72 67.67 34.46 67.67 35.38V42V42ZM51.93 32.48H32.06C24.05 32.48 22.54 33.99 22.54 42C22.54 44.54 23.53 46.93 25.33 48.73C27.13 50.53 29.52 51.52 32.06 51.52C33.9 51.52 35.55 50.66 37.15 49.83C38.69 49.03 40.29 48.21 41.99 48.21C43.69 48.21 45.29 49.04 46.83 49.83C48.43 50.66 50.09 51.52 51.92 51.52C57.17 51.52 61.44 47.25 61.44 42C61.44 37.65 60.95 35.47 59.7 34.22C58.44 32.97 56.26 32.48 51.91 32.48H51.93Z"
      fill="white"
    />
  </svg>,
  "YeuxIcon"
);

export default YeuxIcon;
