import { SnackbarProvider, type SnackbarProviderProps } from "notistack";
import { type ReactElement } from "react";

interface SdeappsSnackbarProviderType extends SnackbarProviderProps {}

function SdeappsSnackbarProvider({
  maxSnack = 3,
  autoHideDuration = 3000,
  ...props
}: SdeappsSnackbarProviderType): ReactElement {
  return (
    <SnackbarProvider
      maxSnack={maxSnack}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      preventDuplicate
      {...props}
    />
  );
}

export default SdeappsSnackbarProvider;
