import { Container, Link as MuiLink, Typography } from "@mui/material";
import PageTitle from "components/molecules/PageTitle";
import { type ReactElement } from "react";

function DisclaimerPage(): ReactElement {
  return (
    <>
      <PageTitle title="Mentions légales" />
      <Container maxWidth="md">
        <Typography textAlign="justify">
          Les données recueillies dans cette application font l'objet d'un traitement informatique
          ayant pour finalité de gérer les commandes des EPI. Equipe-toi respecte la réglementation
          française et européenne sur la protection des données personnelles.
          <br />
          <br />
          En utilisant cette application, vous acceptez que vos actions soient enregistrées dans
          l'unique but de faciliter sa maintenance. Ce traitement est enregistré sous la référence
          2022-01.
          <br />
          <br />
          Conformément aux articles 39 et 40 de la loi du 6 janvier 1978 modifiée en 2004, vous
          pouvez exercer vos droits d'accès et de rectification auprès du Délégué à la Protection
          des Données du SDEA, Franck PERRU (
          <MuiLink component="a" href="mailto:franck.perru@sdea.fr">
            franck.perru@sdea.fr
          </MuiLink>
          ).
        </Typography>
      </Container>
    </>
  );
}

export default DisclaimerPage;
