import { createSvgIcon } from "@mui/material";

const LivreIcon = createSvgIcon(
  <svg viewBox="-3 -2 24 24">
    <path
      fill="currentColor"
      d="M9 0C8.8 0 8.6 0.1 8.4 0.2L0.5 4.6C0.2 4.8 0 5.1 0 5.5V14.5C0 14.9 0.2 15.2 0.5 15.4L8.4 19.8C8.6 19.9 8.8 20 9 20C9.2 20 9.4 19.9 9.6 19.8L10.5 19.3C10.2 18.7 10.1 18 10 17.3V10.6L16 7.2V11C16.7 11 17.4 11.1 18 11.3V5.5C18 5.1 17.8 4.8 17.5 4.6L9.6 0.2C9.4 0.1 9.2 0 9 0ZM9 2.2L15 5.5L13 6.6L7.1 3.2L9 2.2ZM5.1 4.3L11 7.8L9 8.9L3 5.5L5.1 4.3ZM2 7.2L8 10.6V17.3L2 13.9V7.2ZM18.3 13.8L14.7 17.4L13.1 15.8L12 17L14.8 20L19.6 15.2L18.3 13.8Z"
    />
  </svg>,
  "Livre"
);

export default LivreIcon;
