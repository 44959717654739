import { authenticationService } from "@sdeapps/react-core";
import { apiConfig, config } from "config/app-config";

async function createHeaders(scopes: Array<string>): Promise<HeadersInit> {
  const accessToken = await authenticationService.getAccessToken(scopes);

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    "Ocp-Apim-Subscription-Key": `${apiConfig.apimSubscriptionKey}`,
    "Sdeapps-Version": config.appVersion,
    "Sdeapps-Application": config.appName,
  };
}

const networkService = {
  createHeaders,
};

export default networkService;
