import { List } from "@mui/material";
import categories from "constants/Categories";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import { type ReactElement } from "react";
import CommandeHistoryCategorie from "./CommandeHistoryCategorie";

interface CommandeHistoryListProps {
  history: Array<CommandeArticleHistory>;
}

function CommandeHistoryList({ history }: CommandeHistoryListProps): ReactElement {
  return (
    <List>
      {categories.map((value) => {
        const list: Array<CommandeArticleHistory> =
          history.filter((v) => v.categorie === value.dataBaseLabel) ?? [];
        return (
          <CommandeHistoryCategorie key={value.dataBaseLabel} categorie={value} history={list} />
        );
      })}
    </List>
  );
}

export default CommandeHistoryList;
