import { Box } from "@mui/material";
import ScrollToTop from "components/helpers/ScrollToTop";
import TitlesUtil from "components/helpers/TitlesUtil";
import { type ReactElement } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import store from "store/store";
import { Provider } from "react-redux";
import { NavigationMenu, UnauthenticatedNavigationMenu } from "./Header/NavigationMenu";
import { usePersistentNavigationMenu } from "hooks/usePersistentNavigationMenu";

interface RootProps {
  isUnauthenticated?: boolean;
}

function Root({ isUnauthenticated = false }: RootProps): ReactElement {
  const { isNavMenuOpen, setIsNavMenuOpen } = usePersistentNavigationMenu();

  if (isUnauthenticated) {
    return (
      <>
        <Header isUnauthenticated isMenuOpen={isNavMenuOpen} setIsMenuOpen={setIsNavMenuOpen} />
        <Box sx={{ display: "flex", mb: 2 }}>
          <UnauthenticatedNavigationMenu isOpen={isNavMenuOpen} onOpen={setIsNavMenuOpen} />
          <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
            <Outlet />
          </Box>
        </Box>
        <ScrollToTop />
        <TitlesUtil />
      </>
    );
  }

  return (
    <Provider store={store}>
      <Header isMenuOpen={isNavMenuOpen} setIsMenuOpen={setIsNavMenuOpen} />
      <Box sx={{ display: "flex", mb: 2 }}>
        <NavigationMenu isOpen={isNavMenuOpen} onOpen={setIsNavMenuOpen} />
        <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
          <Outlet />
        </Box>
      </Box>
      <ScrollToTop />
      <TitlesUtil />
    </Provider>
  );
}

export default Root;
