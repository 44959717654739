import ToastMessages from "constants/ToastMessages";
import ErrorPage from "pages/errors/ErrorPage";
import img404 from "images/illustration-404.svg";
import { type ReactElement } from "react";

interface NotFoundErrorPageProps {
  message?: string;
}

function NotFoundErrorPage({ message }: NotFoundErrorPageProps): ReactElement {
  return (
    <ErrorPage
      title="404"
      subtitle={ToastMessages.ERROR_NOT_FOUND}
      message={message}
      image={img404}
    />
  );
}

export default NotFoundErrorPage;
