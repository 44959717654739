import type { ReactElement } from "react";
import { routesConfig } from "config/app-config";
import ApplicationRoles from "constants/ApplicationRoles";
import Statut from "constants/Statut";
import FilterCommandesPage from "components/organisms/FilterCommandesPage";
import { Outlet } from "react-router-dom";
import commandesFiltersService from "services/commandesFiltersService";

const AVAILABLE_STATUTS: Array<Statut> = [
  Statut.COMMANDE,
  Statut.EN_PREPARATION,
  Statut.PRET_EN_MAGASIN,
  Statut.LIVRE,
];

function StoreManagerPage(): ReactElement {
  return (
    <>
      <FilterCommandesPage
        availableStatuts={AVAILABLE_STATUTS}
        defaultStatuts={commandesFiltersService.STORE_MANAGER_DEFAULT_STATUTS_FILTER}
        roles={ApplicationRoles.STOREMANAGER}
        hasCommandeLink
        getCommandeUrl={routesConfig.storekeeperCommand.getParameterPath}
        pageTitle="Commandes à traiter"
      />
      <Outlet />
    </>
  );
}

export default StoreManagerPage;
