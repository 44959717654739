import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import { type ReactElement, useEffect, useState } from "react";
import CommandeHistoryLine from "./CommandeHistoryLine";

interface CommandeHistoryArticleProps {
  article: string;
  history: Array<CommandeArticleHistory>;
}

function CommandeHistoryArticle({ article, history }: CommandeHistoryArticleProps): ReactElement {
  const [open, setOpen] = useState(false);
  const [articleQuantity, setArticleQuantity] = useState(0);

  useEffect(() => {
    const otherQte: number = history
      .map((value) => value.quantite)
      .reduce((previous, current) => previous + current, 0);
    setArticleQuantity(otherQte);
  }, [history]);

  function handleClick(): void {
    setOpen(!open);
  }

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ pl: 9 }} selected={open}>
        <ListItemText
          primary={
            <Typography variant="body2">
              {article} (<strong>{articleQuantity}</strong>)
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableContainer sx={{ width: "100%" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Taille</TableCell>
                <TableCell>Qté</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((value) => (
                <CommandeHistoryLine
                  key={value.articleId + value.dateCommande.toString()}
                  articleCommande={value}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
}

export default CommandeHistoryArticle;
