import { Grid } from "@mui/material";
import { routesConfig } from "config/app-config";
import CommandeCard from "components/molecules/cards/CommandeCard/CommandeCard";
import Statut from "constants/Statut";
import type Commande from "models/Commande";
import { useUser } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import type { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import type { StoreProps } from "store/store";

interface CommandeListeProps {
  commandes: Array<Commande>;
  agents?: Array<User>;
  uri?: (id: string) => string;
  item?: boolean;
  displayArticleListHeader?: boolean;
  displayCommentaires?: boolean;
  hasCommandeLink?: boolean;
}

function CommandeListe({
  commandes,
  agents,
  uri,
  item = false,
  displayArticleListHeader = false,
  displayCommentaires = false,
  hasCommandeLink = false,
}: CommandeListeProps): ReactElement {
  const { user } = useUser();
  const commandesStore = useSelector<StoreProps, { loadingCommandsIds: Array<string> }>(
    (state) => state?.commandes
  );

  const location = useLocation();

  if (agents != null && uri != null && location.pathname === routesConfig.validator.path) {
    return (
      <Grid container item={item} xs={item ? 12 : undefined} spacing={4}>
        {commandes.map((commande) => (
          <Grid item xs={12} lg={6} key={commande.commandeId}>
            <CommandeCard
              commande={commande}
              agent={agents.find((u) => u.id === commande.demandeurId)}
              displayArticleListHeader={displayArticleListHeader}
              displayCommentaires={displayCommentaires}
              haveCardAction={commande.statutCommande === Statut.EN_ATTENTE}
              urlCardAction={
                hasCommandeLink && commande.statutCommande === Statut.EN_ATTENTE
                  ? uri(commande.commandeId)
                  : undefined
              }
              isLoading={commandesStore.loadingCommandsIds.includes(commande.commandeId)}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container item={item} xs={item ? 12 : undefined} spacing={4}>
      {commandes.map((commande) => (
        <Grid item xs={12} lg={6} key={commande.commandeId}>
          {agents != null && uri != null ? (
            <CommandeCard
              commande={commande}
              agent={agents.find((u) => u.id === commande.demandeurId)}
              displayArticleListHeader={displayArticleListHeader}
              displayCommentaires={displayCommentaires}
              haveCardAction
              urlCardAction={hasCommandeLink ? uri(commande.commandeId) : undefined}
              isLoading={commandesStore.loadingCommandsIds.includes(commande.commandeId)}
            />
          ) : (
            <CommandeCard
              commande={commande}
              agent={user}
              displayArticleListHeader={displayArticleListHeader}
              displayCommentaires={displayCommentaires}
              isLoading={commandesStore.loadingCommandsIds.includes(commande.commandeId)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default CommandeListe;
