import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { type ReactElement, memo } from "react";

const ArticleCardSkeleton = memo(function ArticleCardSkeleton(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Card variant="outlined" sx={{ border: "none" }}>
      <CardContent>
        <Grid container alignItems="flex-start" spacing={isSmallScreen ? 1 : 2}>
          <Grid
            item
            sx={{ position: "relative", m: "none" }}
            {...(isSmallScreen && { container: true, justifyContent: "center" })}>
            <Skeleton
              variant="circular"
              width={isSmallScreen ? "120px" : "140px"}
              height={isSmallScreen ? "120px" : "140px"}
            />
          </Grid>
          <Grid item xs={12} sm>
            <Typography>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
          <Grid item xs={isSmallScreen || undefined} sx={{ minWidth: "100px" }}>
            <Skeleton height="50px" />
          </Grid>
          <Grid item minWidth="160px" xs={isSmallScreen || undefined}>
            <Skeleton height="50px" />
          </Grid>
          <Grid item minWidth="130px" xs={isSmallScreen || undefined}>
            <Skeleton height="50px" />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
});

export default ArticleCardSkeleton;
