import { routesConfig } from "config/app-config";
import NotFoundErrorPage from "pages/errors/NotFoundErrorPage";
import Root from "components/templates/Root";
import ArticleInformationPage from "pages/catalog/ArticleInformationPage";
import CartPage from "pages/CartPage";
import CataloguePage from "pages/catalog/CataloguePage";
import FlushPage from "pages/FlushPage";
import ModifyArticlePage from "pages/validator/ModifyArticlePage";
import NewArticlePage from "pages/validator/NewArticlePage";
import StoreManagerPage from "pages/magasin/StoreManagerPage";
import StoreManagerCommandePage from "pages/magasin/StoreManagerCommandePage";
import UserCommandPage from "pages/UserCommandPage";
import ValidatorCommandePage from "pages/validator/ValidatorCommandePage";
import ValidatorPage from "pages/validator/ValidatorPage";
import { Navigate, createBrowserRouter } from "react-router-dom";
import StoreTriByArticlePage from "pages/magasin/StoreTriByArticlePage";
import DisclaimerPage from "pages/DisclaimerPage";
import ErrorPage from "pages/errors/ErrorPage";

const authenticatedRouter = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routesConfig.home.path,
        element: <Navigate to={routesConfig.catalog.path} replace />,
      },
      {
        path: routesConfig.catalog.path,
        element: <CataloguePage />,
        children: [
          {
            path: routesConfig.catalogArticle.path,
            element: <ArticleInformationPage />,
          },
        ],
      },
      {
        path: routesConfig.userCommand.path,
        element: <UserCommandPage />,
      },
      {
        path: routesConfig.cart.path,
        element: <CartPage />,
      },
      {
        path: routesConfig.flush.path,
        element: <FlushPage />,
      },
      {
        path: routesConfig.validator.path,
        element: <ValidatorPage />,
        children: [
          {
            path: routesConfig.validatorCommand.path,
            element: <ValidatorCommandePage />,
          },
        ],
      },
      {
        path: routesConfig.storekeeper.path,
        element: <StoreManagerPage />,
        children: [
          {
            path: routesConfig.storekeeperCommand.path,
            element: <StoreManagerCommandePage />,
          },
        ],
      },
      {
        path: routesConfig.storekeeperTriParArticle.path,
        element: <StoreTriByArticlePage />,
      },
      {
        path: routesConfig.createArticle.path,
        element: <NewArticlePage />,
      },
      {
        path: routesConfig.modifyArticle.path,
        element: <ModifyArticlePage />,
      },
      {
        path: routesConfig.disclaimer.path,
        element: <DisclaimerPage />,
      },
      {
        path: routesConfig.any.path,
        element: <NotFoundErrorPage />,
      },
    ],
  },
]);

export default authenticatedRouter;
