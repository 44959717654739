import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import type Statut from "constants/Statut";
import { memo, type ReactElement } from "react";
import StatutChip from "../Chips/StatutChip";

interface StatutInputProps {
  selectedStatuts: Array<Statut>;
  setSelectedStatuts: (s: Array<Statut>) => void;
  availableStatuts: Array<Statut>;
  label: string;
}

const StatutInput = memo(function CommandesFilter({
  selectedStatuts,
  setSelectedStatuts,
  availableStatuts,
  label,
}: StatutInputProps): ReactElement {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedStatuts}
        onChange={(event) => {
          setSelectedStatuts(event.target.value as Array<Statut>);
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected.map((statut) => {
            return <StatutChip key={statut} statut={statut} sx={{ mr: 1 }} />;
          })
        }>
        {availableStatuts.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selectedStatuts.includes(name)} />
            <ListItemText primary={<StatutChip key={name} statut={name} variant="body1" />} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default StatutInput;
