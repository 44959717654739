import {
  Box,
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material";
import RuleIcon from "@mui/icons-material/Rule";
import ApplicationRoles from "constants/ApplicationRoles";
import { config, routesConfig } from "config/app-config";
import { Link, useLocation } from "react-router-dom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SellIcon from "@mui/icons-material/Sell";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CartChip from "components/atoms/Chips/CartChip";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useDispatch, useSelector } from "react-redux";
import type { StoreProps } from "store/store";
import type store from "store/store";
import type Commande from "models/Commande";
import { useUser, Authorization } from "@sdeapps/react-core";
import { getAllCommandes } from "reducers/commandesReducer";
import commandesFiltersService from "services/commandesFiltersService";
import MagasinDeRetraitIcon from "icons/MagasinDeRetraitIcon";

const drawerWidth = 240;

function getDrawerStyle(isOpen: boolean): SxProps<Theme> {
  if (isOpen) {
    return {
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: {
        width: drawerWidth,
        boxSizing: "border-box",
      },
      transition: "width .25s ease",
    };
  } else {
    return {
      width: 0,
      transition: "width .25s ease",
    };
  }
}

type NavigationMenuProps = { isOpen: boolean; onOpen: (v: boolean) => void };

function NavigationMenu({ isOpen = false, onOpen }: NavigationMenuProps): ReactElement {
  const [isValidatorOpen, setIsValidatorOpen] = useState<boolean>(true);
  const [isMagasinierOpen, setIsMagasinierOpen] = useState<boolean>(true);
  const [numberPendingCommandesSst, setNumberPendingCommandesSst] = useState<number>(0);
  const [numberPendingCommandesStoreManager, setNumberPendingCommandesStoreManager] =
    useState<number>(0);

  const { isRoles } = useUser();

  const dispatch = useDispatch<typeof store.dispatch>();

  const commandes = useSelector<StoreProps, { list: Array<Commande> }>((state) => state?.commandes);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();

  // Sur petits écrans, on ferme le menu à la navigation
  useEffect(() => {
    if (isMediumScreen) {
      close();
    }
  }, [location]);

  useEffect(() => {
    if (isRoles(ApplicationRoles.SSTMANAGER) || isRoles(ApplicationRoles.STOREMANAGER)) {
      void dispatch(getAllCommandes());
    }
  }, []);

  useEffect(() => {
    setNumberPendingCommandesSst(
      commandes.list.filter((commande) =>
        commandesFiltersService.SST_DEFAULT_STATUTS_FILTER.includes(commande.statutCommande)
      ).length
    );
    setNumberPendingCommandesStoreManager(
      commandes.list.filter((commande) =>
        commandesFiltersService.STORE_MANAGER_DEFAULT_STATUTS_FILTER.includes(
          commande.statutCommande
        )
      ).length
    );
  }, [commandes]);

  function toggleValidatorOpen(): void {
    setIsValidatorOpen(!isValidatorOpen);
  }

  function toggleMagasinierOpen(): void {
    setIsMagasinierOpen(!isMagasinierOpen);
  }

  function open(): void {
    onOpen(true);
  }

  function close(): void {
    onOpen(false);
  }

  return (
    <SwipeableDrawer
      variant={isMediumScreen ? "temporary" : "persistent"}
      open={isOpen}
      onOpen={open}
      onClose={close}
      sx={getDrawerStyle(isOpen)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <Toolbar />
      <Box sx={{ overflow: "auto", height: "100%", display: "flex", flexDirection: "column" }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={routesConfig.catalog.path}>
              <ListItemIcon>
                <MenuBookIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Catalogue" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={routesConfig.cart.path}>
              <ListItemIcon>
                <ShoppingBasketIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Panier" />
              <CartChip color="error" size="small" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={routesConfig.userCommand.path}>
              <ListItemIcon>
                <SellIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Mes commandes" />
            </ListItemButton>
          </ListItem>

          <Authorization roles={ApplicationRoles.SSTMANAGER}>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton onClick={toggleValidatorOpen}>
                <ListItemIcon>
                  <RuleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Chargé SST" />
                {isValidatorOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={isValidatorOpen} unmountOnExit>
              <List disablePadding sx={{ pl: 4 }}>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to={routesConfig.validator.path}>
                    <ListItemText primary="À traiter" />
                    {numberPendingCommandesSst > 0 && (
                      <Chip color="error" size="small" label={numberPendingCommandesSst} />
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to={routesConfig.createArticle.path}>
                    <ListItemText primary="Nouvel Article" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </Authorization>

          <Authorization roles={ApplicationRoles.STOREMANAGER}>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton onClick={toggleMagasinierOpen}>
                <ListItemIcon>
                  <MagasinDeRetraitIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Magasin" />
                {isMagasinierOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={isMagasinierOpen} unmountOnExit>
              <List disablePadding sx={{ pl: 4 }}>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to={routesConfig.storekeeper.path}>
                    <ListItemText primary="À préparer" />
                    {numberPendingCommandesStoreManager > 0 && (
                      <Chip color="error" size="small" label={numberPendingCommandesStoreManager} />
                    )}
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to={routesConfig.storekeeperTriParArticle.path}>
                    <ListItemText primary="Tri par article" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </Authorization>
        </List>

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: "inline-block",
            textAlign: "center",
            margin: 2,
          }}>
          <Typography component={Link} to={routesConfig.disclaimer.path} color="primary">
            Mentions légales
          </Typography>
          <Typography variant="body2">Version de build : {config.appVersion ?? "local"}</Typography>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}

function UnauthenticatedNavigationMenu({
  isOpen = false,
  onOpen,
}: NavigationMenuProps): ReactElement {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();

  // Sur petits écrans, on ferme le menu à la navigation
  useEffect(() => {
    if (isMediumScreen) {
      close();
    }
  }, [location]);

  function open(): void {
    onOpen(true);
  }

  function close(): void {
    onOpen(false);
  }

  return (
    <SwipeableDrawer
      variant={isMediumScreen ? "temporary" : "persistent"}
      open={isOpen}
      onOpen={open}
      onClose={close}
      sx={getDrawerStyle(isOpen)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <Toolbar />
      <Box sx={{ overflow: "auto", height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: "inline-block",
            textAlign: "center",
            margin: 2,
          }}>
          <Typography component={Link} to={routesConfig.disclaimer.path} color="primary">
            Mentions légales
          </Typography>
          <Typography variant="body2">Version de build : {config.appVersion ?? "local"}</Typography>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}

export { NavigationMenu, UnauthenticatedNavigationMenu };
