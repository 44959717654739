import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Badge, useTheme } from "@mui/material";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";
import { type ReactElement } from "react";
import { useSelector } from "react-redux";
import { type StoreProps } from "store/store";

type CartIconProps = {
  badge?: boolean;
  badgeColor?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
};

function CartIcon({ badge = true, badgeColor = "primary" }: CartIconProps): ReactElement {
  const cart = useSelector<StoreProps, Array<CommandeArticleCartModel>>((state) => state?.cart);
  const theme = useTheme();
  if (badge) {
    return (
      <Badge
        badgeContent={cart.length}
        sx={{
          "& .MuiBadge-badge": {
            right: -3,
            top: 5,
            border: `2px solid white`,
            padding: "0 4px",
            backgroundColor: "white",
            color: theme.palette[badgeColor].main,
          },
        }}>
        <ShoppingBasketIcon />
      </Badge>
    );
  }
  return <ShoppingBasketIcon />;
}

export default CartIcon;
