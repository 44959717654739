import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type Commande from "models/Commande";
import commandesService from "services/commandesService";
import { dateUtil } from "@sdeapps/react-core";

export interface CommandesReducerType {
  list: Array<Commande>;
  loadingCommandsIds: Array<string>;
  isLoading: boolean;
}

export const getAllCommandes = createAsyncThunk("commandes/getAllCommandes", async () => {
  const commandes = await commandesService.getAll();
  commandes.sort((a, b) => dateUtil.compareAsc(a.dateCommande, b.dateCommande));
  return commandes;
});

const commandesSlice = createSlice({
  name: "commandes",
  initialState: {
    list: [] as Array<Commande>,
    loadingCommandsIds: [] as Array<string>,
    isLoading: false,
    status: null,
  },
  reducers: {
    setLoadingCommandsIds: (state, { payload }: { payload: Array<string> | string }) => {
      let newLoadingCommandsIds: Array<string> = [];
      if (payload instanceof Array) {
        newLoadingCommandsIds = payload;
      } else {
        newLoadingCommandsIds = [payload];
      }
      return {
        ...state,
        loadingCommandsIds: [...state.loadingCommandsIds, ...newLoadingCommandsIds],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCommandes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCommandes.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
      state.loadingCommandsIds = [];
    });
    builder.addCase(getAllCommandes.rejected, (state, action) => {
      state.isLoading = false;
      // TODO error handling
    });
  },
});

export const { setLoadingCommandsIds } = commandesSlice.actions;

export default commandesSlice.reducer;
