import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { type PropsWithChildren, type ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransitionSlideLeft, TransitionSlideUp } from "utils/animations";

interface ModalPageTemplateProps extends PropsWithChildren {}

function ModalPageTemplate({ children }: ModalPageTemplateProps): ReactElement {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  function handleDialogClose(): void {
    setOpen(false);
    setTimeout(() => {
      navigate(-1);
    }, 250);
  }

  return (
    <Dialog
      fullScreen
      sx={{ left: { xs: 0, md: "10%", lg: "20%" } }}
      onClose={handleDialogClose}
      TransitionComponent={isSmallScreen ? TransitionSlideUp : TransitionSlideLeft}
      open={open}>
      {children}
    </Dialog>
  );
}

export default ModalPageTemplate;
