import { apiConfig } from "config/app-config";
import type Commande from "models/Commande";
import type CommandeFormModel from "models/CommandeFormModel";
import type Commentaire from "models/Commentaire";
import type PatchData from "models/PatchData";
import networkService from "services/networkService";
import { search } from "jmespath";
import type CommandeArticle from "models/CommandeArticle";
import { dateUtil } from "@sdeapps/react-core";

const commandesEndpoint = "commandes";
const usersEndpoint = "users";
const apiUrl = `${apiConfig.apimBaseUrl}/${apiConfig.ordersApi.apiName}`;
const scope = apiConfig.ordersApi.scopes;

/**
 * Création du header
 * @returns Le header
 */
async function createHeaders(): Promise<HeadersInit> {
  return networkService.createHeaders(scope);
}

/**
 * Récupère toutes les commandes
 * @returns Liste des commandes
 */
async function getAll(): Promise<Array<Commande>> {
  const url = `${apiUrl}/${commandesEndpoint}`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Récupère toutes les commandes passées par un utilisateur
 * !! Attention !! Demande les droits Validateur ! Pour récupérer les commandes de
 * l'utilisateur courant, préférer getAllByMe
 * @param userOid l'id de l'utilisateur
 * @returns Liste des commandes
 */
async function getAllByUser(userOid: string): Promise<Array<Commande>> {
  const url = `${apiUrl}/${usersEndpoint}/${userOid}/${commandesEndpoint}`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Fonction qui retourne l'historique des commandes d'un agent sans les articles refusés.
 * @param userOid est l'id de l'utilisateur dont on veut avoir l'historique des articles commandés.
 * @param newId est l'id de la nouvelle commande.
 * @returns La liste de tout les articles commandés par un agent au cours des 12 derniers mois.
 */
async function getCommandeHistory(userOid: string, newId: string): Promise<Array<CommandeArticle>> {
  const userCommandes = await getAllByUser(userOid);
  const articlesNotRefused = search(userCommandes, "[*].articles[?statutArticle!='REFUSE'] | []");
  const dateOneYearAgo = dateUtil.subMonths(undefined, 12);

  const res = articlesNotRefused.filter(
    (value: CommandeArticle) =>
      dateUtil.isAfter(value.dateCommande, dateOneYearAgo) && value.commandeId !== newId
  );

  return res;
}

/**
 * Récupère toutes les commandes passées par l'utilisateur.
 * @param userOid l'id de l'utilisateur.
 * @returns Liste des commandes.
 */
async function getAllByMe(): Promise<Array<Commande>> {
  const url = `${apiUrl}/me/${commandesEndpoint}`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Récupère une commande à partir de son id.
 * @param commandId l'id de la commande.
 * @returns une Commande.
 */
async function getById(commandId: string): Promise<Commande> {
  const url = `${apiUrl}/${commandesEndpoint}/${commandId}`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Créée une nouvelle commande
 * @param commande la commande à créer
 * @returns la commande créée
 */
async function create(commande: CommandeFormModel): Promise<Commande> {
  const url = `${apiUrl}/${commandesEndpoint}`;
  const options: RequestInit = {
    method: "POST",
    headers: await createHeaders(),
    body: JSON.stringify(commande),
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Créée une nouvelle commande pour un autre agent que soi-même
 * @param commande la commande à créer
 * @returns la commande créée
 */
async function createForAgent(commande: CommandeFormModel, agentId: string): Promise<Commande> {
  const url = `${apiUrl}/${usersEndpoint}/${agentId}/${commandesEndpoint}`;
  const options: RequestInit = {
    method: "POST",
    headers: await createHeaders(),
    body: JSON.stringify(commande),
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Ajoute un commentaire à une commande
 * @param commandeId l'id de la commande
 * @param commentaire le commentaire
 * @returns la commande modifiée
 */
async function addComment(commandeId: string, commentaire: string): Promise<Commande> {
  const url = `${apiUrl}/${commandesEndpoint}/${commandeId}/commentaires`;
  const options: RequestInit = {
    method: "POST",
    headers: await createHeaders(),
    body: JSON.stringify({ value: commentaire }),
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Récupère les commentaires d'une commande à partir de son id
 * @param commandeId l'id de la commande
 * @returns La listes des commentaires
 */
async function getCommentsByCommande(commandeId: string): Promise<Array<Commentaire>> {
  const url = `${apiUrl}/${commandesEndpoint}/${commandeId}/commentaires`;
  const options: RequestInit = {
    method: "GET",
    headers: await createHeaders(),
    mode: "cors",
  };

  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

/**
 * Patche une commande (utilisé pour la validation/refus d'une cmomande)
 * @param commandeId l'id de la commande
 * @param patchData un tableau de PatchCommande contenant les modifications
 * à apporter aux articles de la commande
 * @returns la commande modifiée
 */
async function patchCommande(commandeId: string, patchData: Array<PatchData>): Promise<Commande> {
  const url = `${apiUrl}/${commandesEndpoint}/${commandeId}`;
  const options: RequestInit = {
    method: "PATCH",
    headers: await createHeaders(),
    body: JSON.stringify(patchData),
  };
  return fetch(url, options).then(async (res) => {
    return (await res.json())?.data;
  });
}

const commandesService = {
  getAll,
  getAllByUser,
  getCommandeHistory,
  getById,
  create,
  createForAgent,
  addComment,
  getCommentsByCommande,
  patchCommande,
  getAllByMe,
};
export default commandesService;
