import { type ReactElement, useEffect, useState } from "react";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import CategorieTabs from "components/organisms/CategorieTabs";
import type Article from "models/Article";
import articlesService from "services/articlesService";
import PageTitle from "components/molecules/PageTitle";
import { Outlet } from "react-router-dom";
import CataloguePageSkeleton from "components/organisms/CataloguePageSkeleton";

function CataloguePage(): ReactElement {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [articles, setArticles] = useState<Array<Article>>([]);
  const [isArticlesLoading, setIsArticlesLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getData(): Promise<void> {
      setIsArticlesLoading(true);
      try {
        const allArticles = await articlesService.getAll();
        const filteredList = allArticles.filter((article) => article.isActive);
        filteredList.sort((a, b) => a.categorie?.localeCompare(b.categorie ?? "") ?? 0);
        setArticles(filteredList);
      } catch {
        setArticles([]);
      }
      setIsArticlesLoading(false);
    }

    void getData();
  }, []);

  if (isArticlesLoading) {
    return <CataloguePageSkeleton />;
  }

  return (
    <>
      <PageTitle noBackButton title="Catalogue" />
      <Container maxWidth={isLargeScreen ? "lg" : "xl"} sx={{ xs: { p: 0, m: 0 } }}>
        <CategorieTabs articles={articles} />
      </Container>
      <Outlet />
    </>
  );
}

export default CataloguePage;
