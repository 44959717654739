import { type ReactElement, type SyntheticEvent, useEffect, useState } from "react";
import { TextField, Autocomplete, type SxProps } from "@mui/material";
import { type User } from "@sdeapps/react-core";

interface AgentSelectProps {
  agents: Array<User>;
  value?: User | null;
  onChange?: (...event: Array<any>) => void;
  onValueChange?: (...event: Array<any>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  defaultAgent?: User;
  label?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  sx?: SxProps;
  variant?: "outlined" | "standard" | "filled";
}

/**
 * Input de selection d'un agent
 * @param agents Array<User> Tableau de tous les agents
 * @param value User | null
 * @param onChange (...event: Array<any>) => void
 * @param onValueChange (...event: Array<any>) => void
 * @param readOnly boolean
 * @param defaultValue string valeur par défaut
 * @param defaultAgent User
 * @param label string
 * @param error boolean
 * @param helperText string
 * @param fullWidth boolean
 * @param size "small" | "medium" | undefined
 * @param sx any
 * @param variant "outlined" | "standard" | "filled" | undefined
 */
function AgentSelect({
  agents,
  value,
  onChange,
  onValueChange,
  readOnly = false,
  defaultValue = "",
  defaultAgent,
  label,
  error,
  helperText,
  fullWidth = false,
  size = "medium",
  sx,
  variant = "outlined",
}: AgentSelectProps): ReactElement {
  const [selectedAgent, setSelectedAgent] = useState<User | null>();
  const [inputValue, setInputValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [newAgents, setNewAgents] = useState<Array<User>>([]);

  function _onValueChange(_event: SyntheticEvent<Element, Event>, data: User | null): void {
    if (data != null) {
      setSelectedAgent(data);
      if (onValueChange != null) onValueChange(data);
      if (onChange != null) onChange(data?.id);
    }
  }

  function onInputChange(_event: SyntheticEvent<Element, Event>, newInput: string): void {
    if (newInput === "") {
      setSelectedAgent(null);
      if (onValueChange != null) onValueChange(null);
      if (onChange != null) onChange("");
    }
    setInputValue(newInput);
  }

  function getAgentLabel(user: User): string {
    if (user?.employeeId != null && user.employeeId !== "") {
      return `${user.displayName} (${user.employeeId})`;
    }
    return user.displayName;
  }

  useEffect(() => {
    agents.sort((a: User, b: User) => {
      return a.displayName.localeCompare(b.displayName, "fr");
    });

    if (defaultAgent != null && defaultValue === defaultAgent.id) {
      setNewAgents([defaultAgent, ...agents]);
    } else {
      setNewAgents(agents);
    }
  }, [agents]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      autoHighlight
      openOnFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      size={size}
      value={value ?? selectedAgent}
      onChange={_onValueChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isOptionEqualToValue={(user: User, otherUser: User) => user.id === otherUser.id}
      getOptionLabel={getAgentLabel}
      options={newAgents}
      open={open}
      onOpen={() => !readOnly && setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable={readOnly}
      sx={sx}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            variant={variant}
            label={label}
            placeholder="Nom Prénom"
            InputProps={{
              ...params.InputProps,
              readOnly,
            }}
            error={error}
            helperText={helperText}
          />
        </>
      )}
    />
  );
}

export default AgentSelect;
