import { Button, type SxProps } from "@mui/material";
import { type colorPaletteKey } from "providers/EpicThemeProvider";
import { type ReactElement } from "react";

type StatutButtonProps = {
  checked: boolean;
  onClick: () => void;
  label: string | ReactElement;
  color?: colorPaletteKey;
  sx?: SxProps;
  disabled?: boolean;
};
function StatutButton({
  onClick,
  color,
  checked,
  label,
  sx,
  disabled = false,
}: StatutButtonProps): ReactElement {
  return (
    <Button
      variant={checked ? "contained" : "outlined"}
      onClick={onClick}
      sx={sx}
      color={color}
      disabled={disabled}>
      {label}
    </Button>
  );
}

export default StatutButton;
