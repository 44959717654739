import { createSvgIcon } from "@mui/material";

const AuditionIcon = createSvgIcon(
  <svg viewBox="0 0 84 84">
    <path
      fill="currentColor"
      d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
    />
    <path
      d="M42 5.25C21.7 5.25 5.25 21.7 5.25 42C5.25 62.3 21.7 78.75 42 78.75C62.3 78.75 78.75 62.3 78.75 42C78.75 21.7 62.3 5.25 42 5.25ZM62.37 60.05C62.31 61.18 61.39 62.04 60.33 61.98C59.26 61.91 58.45 60.94 58.51 59.82L59.51 41.41C59.57 40.28 60.49 39.42 61.56 39.49C62.62 39.55 63.44 40.52 63.38 41.65C63.44 41.65 63.49 41.67 63.55 41.67C62.88 36.64 60.68 32.13 57.46 28.69C56.62 28.75 55.11 28.03 53.61 26.77C52.9 26.17 52.29 25.54 51.84 24.95C50.96 25.08 49.53 24.8 48.01 24.14C46.9 23.66 45.96 23.06 45.32 22.49C44.51 22.82 43.33 23.03 42.01 23.03C40.69 23.03 39.51 22.82 38.7 22.49C38.06 23.06 37.12 23.66 36.01 24.14C34.49 24.81 33.06 25.08 32.19 24.95C31.73 25.54 31.13 26.17 30.41 26.77C28.91 28.03 27.4 28.75 26.56 28.69C23.34 32.13 21.14 36.65 20.47 41.67C20.53 41.67 20.58 41.65 20.64 41.65C20.58 40.52 21.39 39.55 22.46 39.49C23.53 39.42 24.44 40.29 24.5 41.41L25.5 59.82C25.56 60.95 24.74 61.92 23.68 61.98C22.61 62.04 21.69 61.18 21.64 60.05C18.43 60.24 15.62 56.28 15.34 51.2C15.21 48.81 15.67 46.61 16.52 44.92V44.89C16.52 30.04 27.96 17.95 42.01 17.95C56.06 17.95 67.51 30.04 67.51 44.89V44.92C68.36 46.61 68.82 48.82 68.69 51.2C68.41 56.28 65.59 60.24 62.39 60.05H62.37Z"
      fill="white"
    />
  </svg>,
  "AuditionIcon"
);

export default AuditionIcon;
