enum ToastMessages {
  ERROR = "Oups ! Une erreur est survenue.",
  ERROR_RETRY = "Une erreur est survenue. Merci de réessayer ultérieurement.",
  ERROR_UPDATE = ERROR_RETRY,
  ERROR_NOT_FOUND = "Nous n'avons pas trouvé la ressource à laquelle vous essayez d'accéder.",
  ERROR_FORBIDDEN = "Vous n'avez pas les droits nécessaires pour accéder à cette ressource.",
  ERROR_INTERNAL_SERVER_ERROR = ERROR,
}

export default ToastMessages;
