enum MagasinDeRetrait {
  MAGASINCENTRAL = "Magasin central",
  BENFELD = "Benfeld",
  BITCHE = "Bitche",
  DURRENBACH = "Durrenbach",
  HAGUENAU = "Haguenau",
  HOCHFELDEN = "Hochfelden",
  MOLSHEIM = "Molsheim",
  SAVERNE = "Saverne",
  SELTZ = "Seltz",
}

export default MagasinDeRetrait;
