import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import MagasinDeRetraitIcon from "icons/MagasinDeRetraitIcon";
import { type ReactElement } from "react";
import { type TypographyProps } from "@mui/system";

interface DisplayMagasinDeRetraitProps extends TypographyProps {
  magasinDeRetrait: string;
  item?: boolean;
  noIcon?: boolean;
}

function DisplayMagasinDeRetrait({
  magasinDeRetrait,
  item = false,
  noIcon = false,
  ...typographyProps
}: DisplayMagasinDeRetraitProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid item={item} container xs={item || undefined} gap={1} alignItems="center">
      {!noIcon && (
        <Grid item container xs="auto">
          <MagasinDeRetraitIcon fontSize="small" color="primary" />
        </Grid>
      )}
      <Grid item xs>
        <Typography
          {...typographyProps}
          color="text.primary"
          variant={isSmallScreen ? "body2" : "body1"}>
          {!isSmallScreen && "Magasin de retrait : "}
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            component="strong"
            color="primary">
            {magasinDeRetrait}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DisplayMagasinDeRetrait;
