import type { ReactElement } from "react";
import FilterArticlesPage from "components/organisms/FilterArticlesPage";
import ApplicationRoles from "constants/ApplicationRoles";
import Statut from "constants/Statut";

const AVAILABLE_STATUTS: Array<Statut> = [
  Statut.COMMANDE,
  Statut.EN_PREPARATION,
  Statut.PRET_EN_MAGASIN,
  Statut.LIVRE,
];

function StoreTriByArticlePage(): ReactElement {
  return (
    <FilterArticlesPage
      availableStatuts={AVAILABLE_STATUTS}
      roles={ApplicationRoles.STOREMANAGER}
      pageTitle="Tri par article"
    />
  );
}

export default StoreTriByArticlePage;
