enum Statut {
  EN_ATTENTE = "EN_ATTENTE",
  REFUSE = "REFUSE",
  COMMANDE = "COMMANDE",
  EN_PREPARATION = "EN_PREPARATION",
  PRET_EN_MAGASIN = "PRET_EN_MAGASIN",
  ANNULE = "ANNULE",
  LIVRE = "LIVRE",
}

export default Statut;
