import { Grid, type SxProps, Tab, Typography, useMediaQuery } from "@mui/material";
import { type Theme, useTheme } from "@mui/system";
import type Article from "models/Article";
import ArticleCard from "components/molecules/cards/ArticleCard";
import categories from "constants/Categories";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import {
  type ReactElement,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

const sxTab: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.grey.A700,
  backgroundColor: "transparent",
  position: "relative",
  cursor: "pointer",
  "& .MuiSvgIcon-root": {
    fontSize: 35,
    marginBottom: "20px",
  },
  "&:hover": {
    color: theme.palette.primary.main,
  },
  ".label-categorie": { position: "absolute", bottom: "3px" },
});

function getGridRootParent(parent: HTMLElement | null): HTMLElement | null {
  if (parent == null) {
    return null;
  }
  if (parent?.classList.contains("MuiContainer-root") ?? false) {
    return parent;
  } else {
    return getGridRootParent(parent?.parentElement);
  }
}

function calculatePixelsToScroll(el: Element, headerHeight: number): number {
  let parent = el.parentElement;
  parent = getGridRootParent(parent);

  if (parent != null) {
    const paddingTop = window.getComputedStyle(parent, null).getPropertyValue("padding-top");
    let n: number = 16;
    try {
      n = parseInt(/\d*/i.exec(paddingTop)?.at(0) ?? "16");
    } catch (error) {}
    const pixelsToScroll = parent.offsetTop + 2 + n - headerHeight;

    return pixelsToScroll;
  } else {
    return 0;
  }
}

interface CategorieTabsProps {
  articles: Array<Article>;
}

function CategorieTabs({ articles }: CategorieTabsProps): ReactElement {
  const [value, setValue] = useState<string>(categories[0].dataBaseLabel);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  function calculateHeaderHeight(): void {
    const height = document.querySelector(".real-header")?.getBoundingClientRect()?.height ?? 64;
    setHeaderHeight(height);
  }

  useEffect(() => {
    window.addEventListener("resize", calculateHeaderHeight);
    calculateHeaderHeight();
    return () => {
      window.removeEventListener("resize", calculateHeaderHeight);
    };
  }, []);

  const sxTabsList: SxProps = useMemo(() => {
    return {
      position: "sticky",
      top: `${headerHeight}px`,
      zIndex: "1099",
      backgroundColor: "white",
      ".MuiTabs-flexContainer": {
        md: { justifyContent: "center" },
      },
    };
  }, [headerHeight]);

  const scrollToTop = useCallback(
    (event: SyntheticEvent): void => {
      if (event.target instanceof Element) {
        const el = event.target;
        const pixelsToScroll = calculatePixelsToScroll(el, headerHeight);
        if (window.scrollY > pixelsToScroll) {
          window.scrollTo(0, pixelsToScroll);
        }
      }
    },
    [headerHeight]
  );

  function handleChange(event: SyntheticEvent, newValue: string): void {
    setValue(newValue);
  }

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={sxTabsList}>
        {categories.map((e) => (
          <Tab
            value={e.dataBaseLabel}
            sx={sxTab}
            key={e.dataBaseLabel}
            icon={<e.icon />}
            label={<Typography className="label-categorie">{e.printLabel}</Typography>}
            onClick={scrollToTop}
          />
        ))}
      </TabList>
      {categories.map((e) => (
        <TabPanel value={e.dataBaseLabel} key={e.dataBaseLabel}>
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems="center"
            spacing={isLargeScreen ? 2 : 4}>
            {articles?.map(
              (article) =>
                article.categorie === e.dataBaseLabel && (
                  <Grid item xs={12} lg={6} key={article.id}>
                    <ArticleCard article={article} addToCartUI isContentLink />
                  </Grid>
                )
            )}
          </Grid>
        </TabPanel>
      ))}
    </TabContext>
  );
}

export default CategorieTabs;
