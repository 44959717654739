import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
} from "@mui/material";
import MagasinDeRetrait from "constants/MagasinDeRetrait";
import { memo, type ReactElement } from "react";

type MagasinDeRetraitInputCommonProps = {
  availablesCentresRetrait?: Array<string>;
  helperText?: string;
  label?: string;
  required?: boolean;
};

type MagasinDeRetraitInputUniqueProps = {
  selectedCentreRetrait: string;
  selectedCentresRetrait?: never;
  setSelectedCentreRetrait: (t: string) => void;
  setSelectedCentresRetrait?: never;
  unique: boolean;
  multiple?: never;
};

type MagasinDeRetraitInputMultipleProps = {
  selectedCentreRetrait?: never;
  selectedCentresRetrait: Array<string>;
  setSelectedCentreRetrait?: never;
  setSelectedCentresRetrait: (t: Array<string>) => void;
  unique?: never;
  multiple: boolean;
};

type MagasinDeRetraitInputProps = MagasinDeRetraitInputCommonProps &
  (MagasinDeRetraitInputUniqueProps | MagasinDeRetraitInputMultipleProps);

const MagasinDeRetraitInput = memo(function CommandesFilter({
  selectedCentreRetrait,
  selectedCentresRetrait,
  setSelectedCentreRetrait,
  setSelectedCentresRetrait,
  availablesCentresRetrait = Object.values(MagasinDeRetrait),
  helperText,
  unique = false,
  multiple = false,
  label = "",
  required = false,
}: MagasinDeRetraitInputProps): ReactElement {
  function handleMultipleChange(event: SelectChangeEvent<Array<string>>): void {
    if (setSelectedCentresRetrait != null) {
      setSelectedCentresRetrait(event.target.value as Array<string>);
    }
  }

  function handleUniqueChange(event: SelectChangeEvent): void {
    if (setSelectedCentreRetrait != null) {
      setSelectedCentreRetrait(event.target.value);
    }
  }

  if (multiple && setSelectedCentresRetrait != null) {
    return (
      <FormControl fullWidth size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={selectedCentresRetrait}
          onChange={handleMultipleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) =>
            selected.map((s) => {
              return <Chip key={s} variant="outlined" label={s} size="small" />;
            })
          }>
          {availablesCentresRetrait.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedCentresRetrait.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {required && helperText != null && selectedCentresRetrait?.length === 0 && (
          <FormHelperText>
            <em>{helperText}</em>
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  if (unique && setSelectedCentreRetrait != null) {
    return (
      <FormControl fullWidth size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          value={selectedCentreRetrait}
          onChange={handleUniqueChange}
          input={<OutlinedInput label={label} />}
          displayEmpty>
          <MenuItem disabled value="">
            <em>Veuillez sélectionner un magasin de retrait</em>
          </MenuItem>
          {availablesCentresRetrait.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {required && helperText != null && selectedCentreRetrait === "" && (
          <FormHelperText>
            <em>{helperText}</em>
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  return <></>;
});

export default MagasinDeRetraitInput;
