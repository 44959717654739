import { Skeleton, TableCell, type TableCellProps, TableRow, Typography } from "@mui/material";
import { type ReactElement } from "react";

function TableCellSkeleton(props: TableCellProps): ReactElement {
  return (
    <TableCell {...props}>
      <Typography>
        <Skeleton />
      </Typography>
    </TableCell>
  );
}

function TriByArticleLineSkeleton(): ReactElement {
  return (
    <TableRow sx={{ ".MuiTableCell-root": { paddingX: 1 } }}>
      {[...Array(7)].map((_, i) => (
        <TableCellSkeleton key={i} />
      ))}
      <TableCellSkeleton sx={{ "&.MuiTableCell-root": { width: "375px" } }} />
      <TableCell sx={{ width: "46px" }}></TableCell>
    </TableRow>
  );
}
export default TriByArticleLineSkeleton;
