import type Commande from "models/Commande";
import { Authorization } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import graphService from "services/graphService";
import commandesService from "services/commandesService";
import type PatchData from "models/PatchData";
import ModalPageTemplate from "components/templates/ModalPageTemplate";
import CommandePageTemplateSkeleton from "components/templates/CommandePageTemplateSkeleton";
import NotFoundErrorPage from "pages/errors/NotFoundErrorPage";
import ApplicationRoles from "constants/ApplicationRoles";
import StoreManagerCommandePageTemplate from "components/templates/StoreManagerCommandePageTemplate";
import { useDispatch } from "react-redux";
import type store from "store/store";
import { getAllCommandes, setLoadingCommandsIds } from "reducers/commandesReducer";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";

/**
 * Page réservée aux Magasiniers, listant les commandes en attente de préparation ou traitement
 */
function StoreManagerCommandePage(): ReactElement {
  const { commandeId } = useParams();
  const [commande, setCommande] = useState<Commande>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [commentaire, setCommentaire] = useState<string>("");
  const [articlesList, setArticlesList] = useState<Array<CommandeArticleFormModel>>([]);
  const [demandeur, setDemandeur] = useState<User | undefined>();

  const navigate = useNavigate();
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    async function getAgent(agentId: string): Promise<void> {
      const fetchedAgents = await graphService.getAgentsByIds([agentId]);
      setDemandeur(fetchedAgents.find((u) => u.id === agentId));
    }

    async function getCommande(): Promise<void> {
      if (commandeId == null) {
        // TODO meilleure gestion des erreurs
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return new Promise(() => {});
      }
      const fetchedCommande: Commande = await commandesService.getById(commandeId);
      fetchedCommande.articles.sort((a, b) => {
        return a.commandeArticleNumber - b.commandeArticleNumber;
      });

      setCommande(fetchedCommande);
      void getAgent(fetchedCommande.demandeurId);

      setArticlesList([...fetchedCommande.articles]);

      setIsLoading(false);
    }

    void getCommande();
  }, [commandeId]);

  async function updateCommande(): Promise<void> {
    if (commandeId == null) {
      // TODO meilleure gestion des erreurs
      return;
    }
    setIsSending(true);
    dispatch(setLoadingCommandsIds(commandeId));

    const statutsPatches: Array<PatchData> = articlesList.map((article) => {
      return {
        op: "replace",
        path: `/articles/${article.commandeArticleNumber}/statutArticle`,
        value: article.statutArticle!,
      };
    });

    try {
      if (statutsPatches != null) {
        if (commentaire.trim().length !== 0) {
          await commandesService.addComment(commandeId, commentaire);
        }
        await commandesService.patchCommande(commandeId, statutsPatches);
      } else throw new Error();
      // TODO error handling
    } catch (error) {
      // TODO error handling
      console.error(error);
    } finally {
      setIsSending(false);
      void dispatch(getAllCommandes());
    }
    navigate(-1);
  }

  if (isLoading) {
    return (
      <ModalPageTemplate>
        <CommandePageTemplateSkeleton />
      </ModalPageTemplate>
    );
  }
  if (commande == null || commandeId == null) {
    return (
      <ModalPageTemplate>
        <NotFoundErrorPage />
      </ModalPageTemplate>
    );
  }

  return (
    <ModalPageTemplate>
      <Authorization roles={ApplicationRoles.STOREMANAGER}>
        <StoreManagerCommandePageTemplate
          demandeur={demandeur}
          articlesList={articlesList}
          updateCommande={updateCommande}
          isSending={isSending}
          setArticlesList={setArticlesList}
          commentaire={commentaire}
          setCommentaire={setCommentaire}
          commande={commande}
        />
      </Authorization>
    </ModalPageTemplate>
  );
}

export default StoreManagerCommandePage;
