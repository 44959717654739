import { createSvgIcon } from "@mui/material";

const CorpsIcon = createSvgIcon(
  <svg viewBox="0 0 84 84">
    <path
      fill="currentColor"
      d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
    />
    <path
      d="M42 5.25C21.7 5.25 5.25 21.71 5.25 42C5.25 62.29 21.7 78.75 42 78.75C62.3 78.75 78.75 62.3 78.75 42C78.75 21.7 62.3 5.25 42 5.25ZM19.67 42.6C24.02 40.62 26.8 35.52 26.14 30.06L24.93 18.36L28.85 17.81L34.39 47.92H19.68V42.61L19.67 42.6ZM42 62.69C42 65.14 40.24 67.12 38.06 67.12L23.61 65.64C21.43 65.64 19.67 63.65 19.67 61.21V58.25H42V62.69ZM42 55.3H19.67V50.87H42V55.3ZM42 47.91H37.48L31.86 17.38L35.44 16.88L42 33.14V47.92V47.91ZM48.57 16.87L52.15 17.37L46.53 47.9H43.66V32.8L42.9 30.91L48.58 16.86L48.57 16.87ZM64.33 61.21C64.33 63.66 62.57 65.64 60.39 65.64L45.94 67.12C44.75 67.12 43.7 66.52 42.98 65.58C43.41 64.72 43.65 63.73 43.65 62.68V58.24H64.33V61.2V61.21ZM64.33 55.29H43.65V50.86H64.33V55.29ZM64.33 47.9H49.62L55.17 17.79L59.09 18.34L57.87 30.04C57.21 35.51 59.99 40.6 64.34 42.58V47.89L64.33 47.9Z"
      fill="white"
    />
  </svg>,
  "CorpsIcon"
);

export default CorpsIcon;
