import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Link,
  type SxProps,
  type Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type Commande from "models/Commande";
import { type User, dateUtil } from "@sdeapps/react-core";
import ArticleList from "./ArticleList";
import statutMap from "constants/SatutMap";
import StatutChip from "components/atoms/Chips/StatutChip";
import CommandeStatutIcon from "components/molecules/cards/CommandeCard/CommandeStatutIcon";
import DisplayMagasinDeRetrait from "components/molecules/DisplayMagasinDeRetrait";
import { type ReactElement } from "react";
import CommandeCommentaires from "./CommandeCommentaires";

interface CommandeCardProps {
  commande: Commande;
  agent?: User | null;
  haveCardAction?: boolean;
  urlCardAction?: string;
  displayArticleListHeader?: boolean;
  displayCommentaires?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

function CommandeCard({
  commande,
  agent,
  haveCardAction = false,
  urlCardAction,
  displayArticleListHeader = false,
  displayCommentaires = false,
  isLoading = false,
  onClick,
}: CommandeCardProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const cardStyle: SxProps<Theme> = (theme: Theme) => ({
    width: "100%",
    position: "relative",
    ".MuiCardContent-root:last-child": { pb: 1 },
    ...(haveCardAction &&
      !isLoading && {
        display: "block",
        color: "inherit",
        textDecoration: "none",
        cursor: "pointer",
        "&:hover": {
          boxShadow: theme.shadows[5],
        },
      }),
  });

  return (
    <Card
      variant="outlined"
      sx={cardStyle}
      onClick={onClick}
      {...(haveCardAction &&
        !isLoading &&
        urlCardAction != null && {
          component: Link,
          href: urlCardAction,
        })}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item sx={{ height: "3Opx" }}>
              <CommandeStatutIcon statutAssociation={statutMap.get(commande.statutCommande)} />
            </Grid>
            <Grid item xs>
              <Typography variant={isSmallScreen ? "body1" : "subtitle2"}>
                {agent?.displayName ?? "???"} - {agent?.employeeId ?? "???"}, du{" "}
                {dateUtil.format(commande.dateCommande, "dd/MM/yyyy")}
              </Typography>
              <StatutChip
                sx={{ background: "none", p: 0, display: "block" }}
                statut={commande.statutCommande}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <DisplayMagasinDeRetrait magasinDeRetrait={commande.magasinDeRetrait} />
          </Grid>
          <ArticleList articles={commande.articles} displayHeader={displayArticleListHeader} />
          {displayCommentaires && <CommandeCommentaires commentaires={commande.commentaires} />}
        </Grid>
      </CardContent>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(255,255,255,0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress size="5em" sx={{ width: "100%" }} />
        </Box>
      )}
    </Card>
  );
}

export default CommandeCard;
