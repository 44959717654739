import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { type ReactElement } from "react";
import { dateUtil } from "@sdeapps/react-core";
import TodayIcon from "@mui/icons-material/Today";
import { type TypographyProps } from "@mui/system";

interface DisplayCommandeDateProps extends TypographyProps {
  dateCommande: string | Date;
  item?: boolean;
  noIcon?: boolean;
}

function DisplayCommandeDate({
  dateCommande,
  item = false,
  noIcon = false,
  ...typographyProps
}: DisplayCommandeDateProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid item={item} container xs={item ? "auto" : undefined} gap={1} alignItems="center">
      {!noIcon && (
        <Grid item container xs="auto">
          <TodayIcon fontSize="small" color="primary" />
        </Grid>
      )}
      <Grid item xs>
        <Typography
          {...typographyProps}
          color="text.primary"
          variant={isSmallScreen ? "body2" : "body1"}>
          {dateUtil.format(dateCommande, "dd/MM/yyyy")}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DisplayCommandeDate;
