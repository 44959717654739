import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TriByArticleLine from "components/molecules/tables/TriByArticleLine";
import TriByArticleLineHeader from "components/molecules/tables/TriByArticleLineHeader";
import TriByArticleLineSkeleton from "components/molecules/tables/TriByArticleLineSkeleton";
import type CommandeArticleTri from "models/CommandeArticleTri";
import { type User } from "@sdeapps/react-core";
import { type ReactElement } from "react";

interface TriByArticleListProps {
  articles: Array<CommandeArticleTri>;
  agents: Array<User>;
  isLoading: boolean;
}

function TriByArticleList({
  articles,
  agents,
  isLoading = false,
}: TriByArticleListProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallScreen) {
    return (
      <Typography>
        Cette page n'est disponible que pour les résolutions supérieures à{" "}
        {theme.breakpoints.values.md}px.
      </Typography>
    );
  }

  return (
    <TableContainer sx={{ width: "100%" }} item component={Grid} xs={12}>
      <Table size="small">
        <TriByArticleLineHeader />
        <TableBody>
          {!isLoading && articles.length === 0 && (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <Typography>
                  Aucun article de commande ne correspond aux filtres sélectionnés
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {isLoading
            ? [...Array(15).keys()].map((i) => <TriByArticleLineSkeleton key={i} />)
            : articles.map((article) => {
                const agent = agents.find((a) => a.id === article.demandeurId);

                return (
                  <TriByArticleLine
                    key={`${article.commandeId}${article.commandeArticleNumber}`}
                    article={article}
                    agent={agent}
                  />
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default TriByArticleList;
