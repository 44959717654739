import { Container } from "@mui/material";
import PageTitle from "components/molecules/PageTitle";
import CommandeListe from "components/organisms/CommandeListe";
import type Commande from "models/Commande";
import { useUser, dateUtil } from "@sdeapps/react-core";
import { type ReactElement, useEffect, useState } from "react";
import commandesService from "services/commandesService";
import FilterCommandesPageSkeleton from "components/organisms/FilterCommandesPageSkeleton";

function UserCommandPage(): ReactElement {
  const [userCommandes, setUserCommandes] = useState<Array<Commande>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useUser();

  async function getData(): Promise<void> {
    setIsLoading(true);
    const commandes = await commandesService.getAllByMe();
    commandes.sort((a, b) => dateUtil.compareDesc(a.dateCommande, b.dateCommande));
    setUserCommandes(commandes);
    setIsLoading(false);
  }

  useEffect(() => {
    if (user != null) {
      void getData();
    }
  }, []);

  return isLoading ? (
    <FilterCommandesPageSkeleton />
  ) : (
    <>
      <PageTitle title="Mes commandes" />
      <Container maxWidth="xl">
        <CommandeListe
          item
          commandes={userCommandes}
          displayArticleListHeader
          displayCommentaires
        />
      </Container>
    </>
  );
}

export default UserCommandPage;
