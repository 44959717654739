import { TableCell, TableRow } from "@mui/material";
import StatutChip from "components/atoms/Chips/StatutChip";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import { type ReactElement } from "react";
import { dateUtil } from "@sdeapps/react-core";

interface CommandeHistoryLineProps {
  articleCommande: CommandeArticleHistory;
}

function CommandeHistoryLine({ articleCommande }: CommandeHistoryLineProps): ReactElement {
  return (
    <TableRow key={articleCommande.articleId}>
      <TableCell>{dateUtil.format(articleCommande.dateCommande, "dd/MM/yy")}</TableCell>
      <TableCell>
        {articleCommande.taille === "Taille unique" ? "TU" : articleCommande.taille}
      </TableCell>
      <TableCell>{articleCommande.quantite}</TableCell>
      <TableCell align="right" sx={{ width: { xs: "110px", md: "120px" } }}>
        <StatutChip
          statut={articleCommande.statutArticle}
          sx={{ width: "100%" }}
          isSmall
          textAlign="center"
        />
      </TableCell>
    </TableRow>
  );
}

export default CommandeHistoryLine;
