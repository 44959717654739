import {
  ThemeProvider,
  createTheme,
  type ThemeOptions,
  responsiveFontSizes,
} from "@mui/material/styles";
import { forwardRef, type PropsWithChildren, type ReactElement } from "react";
import { frFR } from "@mui/material/locale";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";
import { type LinkProps } from "@mui/material/Link";

/** Ceci sert à customizer les composants MUI Link et Button pour utiliser
 * le lien de react-router avec la prop "href" mappée sur "to"
 */
// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
  interface TypographyVariants {
    barcode1: React.CSSProperties;
    barcode2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    barcode1?: React.CSSProperties;
    barcode2?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    barcode1: true;
    barcode2: true;
  }
}

const theme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: "#e0f3f8",
      main: "#017cac",
      dark: "#01638a",
    },
    secondary: {
      light: "#FCDBE1",
      main: "#EF4565",
      dark: "#992C41",
    },
    info: {
      light: "#EFF1F8",
      main: "#617ABB",
      dark: "#27314B",
    },
    success: {
      light: "#E5F4F0",
      main: "#02986A",
      dark: "#013D2A",
    },
    warning: {
      light: "#FFF8ED",
      main: "#FFBF4D",
      dark: "#664C1F",
    },
    error: {
      light: "#FCEFED",
      main: "#E1604D",
      dark: "#5A261F",
    },
    text: {
      primary: "#4B515D",
      secondary: "#585A5D",
      disabled: "#888B90",
    },
    aTraiter: {
      light: "#DDE0F1",
      main: "#5666B8",
      dark: "#343D6E",
      contrastText: "#FFF",
    },
    refuse: {
      light: "#F9DFDB",
      main: "#E1604D",
      dark: "#873A2E",
      contrastText: "#FFF",
    },
    preparation: {
      light: "#EFDAE7",
      main: "#B04589",
      dark: "#6A2952",
      contrastText: "#FFF",
    },
    commande: {
      light: "#FFF4CD",
      main: "#DD9200",
      dark: "#855800",
      contrastText: "#FFF",
    },
    pret: {
      light: "#BAFFD6",
      main: "#106633",
      dark: "#0A3D1F",
      contrastText: "#FFF",
    },
    livre: {
      light: "#E0F3F8",
      main: "#026C99",
      dark: "#01638A",
      contrastText: "#FFF",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "sans serif"',
    fontSize: 18,
    h1: {
      fontSize: "2.44rem",
      fontWeight: "700",
    },
    h2: {
      fontSize: "1.95rem",
      fontWeight: "700",
    },
    h3: {
      fontSize: "1.56rem",
      fontWeight: "600",
    },
    h4: {
      fontSize: "1.4rem",
      fontWeight: "600",
    },
    h5: {
      fontSize: "1.3rem",
      fontWeight: "600",
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: "600",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: "400",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: "400",
    },
    body2: {
      fontSize: "0.8rem",
      fontWeight: "400",
    },
    button: {
      fontSize: "1rem",
      textTransform: "none",
    },
    caption: {
      fontSize: "0.8rem",
      color: "grey",
    },
    barcode1: {
      fontFamily: '"Libre Barcode 39 Text"',
      fontSize: "2.5rem",
      fontWeight: "400",
    },
    barcode2: {
      fontFamily: '"Libre Barcode 39"',
      fontSize: "3rem",
      fontWeight: "400",
    },
  },
  /** On customize les composants MUI Link et Button pour utiliser
   * le lien de react-router avec la prop "href" mappée sur "to"
   */
  components: {
    MuiLink: {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      defaultProps: {
        component: LinkBehavior,
        variant: "body1",
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          barcode1: "span",
          barcode2: "p",
        },
      },
    },
  },
};

const tempTheme = createTheme(theme, frFR);
const EpicTheme = responsiveFontSizes(tempTheme);

function EpicThemeProvider({ children }: PropsWithChildren): ReactElement {
  return <ThemeProvider theme={EpicTheme}>{children}</ThemeProvider>;
}

export { EpicTheme };
export default EpicThemeProvider;

declare module "@mui/material/styles" {
  interface Palette {
    aTraiter: Palette["primary"];
    refuse: Palette["primary"];
    preparation: Palette["primary"];
    commande: Palette["primary"];
    pret: Palette["primary"];
    livre: Palette["primary"];
  }

  interface PaletteOptions {
    aTraiter: PaletteOptions["primary"];
    refuse: PaletteOptions["primary"];
    preparation: PaletteOptions["primary"];
    commande: PaletteOptions["primary"];
    pret: PaletteOptions["primary"];
    livre: PaletteOptions["primary"];
  }
}

// Update the Button's "color" prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    aTraiter: true;
    refuse: true;
    preparation: true;
    commande: true;
    pret: true;
    livre: true;
    grey: true;
  }
}

export type colorPaletteKey =
  | "primary"
  | "secondary"
  | "inherit"
  | "grey"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "aTraiter"
  | "refuse"
  | "preparation"
  | "commande"
  | "pret"
  | "livre"
  | undefined;
