import { type SxProps, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { type ReactElement } from "react";

const bold: SxProps = { fontWeight: 600 };

function TriByArticleLineHeader(): ReactElement {
  return (
    <TableHead>
      <TableRow sx={{ ".MuiTableCell-root": { paddingX: 1 } }}>
        <TableCell size="medium">
          <Typography sx={bold}>Date</Typography>
        </TableCell>
        <TableCell size="medium">
          <Typography sx={bold}>Nom de l'agent</Typography>
        </TableCell>
        <TableCell size="medium">
          <Typography sx={bold}>Matricule</Typography>
        </TableCell>
        <TableCell size="medium">
          <Typography sx={bold}>Magasin retrait</Typography>
        </TableCell>
        <TableCell size="medium">
          <Typography sx={bold}>Qté</Typography>
        </TableCell>
        <TableCell size="medium">
          <Typography sx={bold}>Taille</Typography>
        </TableCell>
        <TableCell sx={{ "&.MuiTableCell-root": { paddingX: 0 } }} size="medium">
          <Typography sx={bold}>Statut</Typography>
        </TableCell>
        <TableCell size="medium"></TableCell>
      </TableRow>
    </TableHead>
  );
}
export default TriByArticleLineHeader;
