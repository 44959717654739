import {
  Container,
  Grid,
  Skeleton,
  type SxProps,
  type Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PageTitleSkeleton from "components/molecules/PageTitleSkeleton";
import ArticleCardSkeleton from "components/molecules/cards/ArticleCardSkeleton";
import { type ReactElement } from "react";

const noMarginsOnSmall: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.only("xs")]: {
    p: 0,
    m: 0,
  },
});

function CataloguePageSkeleton(): ReactElement {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      <PageTitleSkeleton noBackButton />
      <Container maxWidth={isLargeScreen ? "lg" : "xl"} sx={noMarginsOnSmall}>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          spacing={isLargeScreen ? 2 : 4}>
          <Grid item container xs={12} alignContent="center" justifyContent="center">
            <Grid item xs={8}>
              <Skeleton height="70px" />
            </Grid>
          </Grid>
          {[...Array(5)].map((v, i) => (
            <Grid item xs={12} lg={6} key={i}>
              <ArticleCardSkeleton />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default CataloguePageSkeleton;
