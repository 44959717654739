import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Link as MuiLink,
  type MenuProps,
  type PaperProps,
  Avatar,
} from "@mui/material";
import { type ReactElement, useState } from "react";
import HeaderAvatar from "./HeaderAvatar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import CartIcon from "icons/CartIcon";
import { envConfig, routesConfig } from "config/app-config";
import { Link as ReactRouterDomLink } from "react-router-dom";
import { authenticationService } from "@sdeapps/react-core";

function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function getEnvName(): string {
  const env = envConfig.name;
  if (env?.length > 0 && env?.toLocaleLowerCase() !== "prod") {
    return `- ${capitalizeFirstLetter(env)}`;
  }
  return "";
}

function handleLogout(): void {
  void authenticationService.logout();
}

const paperPropsTriangle: PaperProps = {
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgb(255,255,255))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

/** Menu contextuel du profil utilisateur */
function LogoutMenu({ ...props }: MenuProps): ReactElement {
  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={paperPropsTriangle}
      {...props}>
      <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
    </Menu>
  );
}

type HeaderProps = {
  isMenuOpen: boolean;
  setIsMenuOpen: (v: boolean) => void;
  isUnauthenticated?: boolean;
};

function Header({
  isMenuOpen = false,
  setIsMenuOpen,
  isUnauthenticated = false,
}: HeaderProps): ReactElement {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isLogoutMenuOpen, setIsLogoutMenuOpen] = useState(false);

  function handleLogoutMenuOpen(event: React.MouseEvent<HTMLElement>): void {
    setAnchorElUser(event.currentTarget);
    setIsLogoutMenuOpen(true);
  }

  function handleLogoutMenuClose(): void {
    setIsLogoutMenuOpen(false);
  }

  return (
    <>
      <AppBar sx={{ position: "relative", visibility: "hidden" }}>
        <Toolbar />
      </AppBar>
      <AppBar className="real-header" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: { xs: 0, sm: 2 } }}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}>
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <MuiLink sx={{ textDecoration: "none" }} href="/" color="inherit">
            <Typography variant="h2" sx={{ display: "inline-block", verticalAlign: "middle" }}>
              Équipe-toi {getEnvName()}
            </Typography>
          </MuiLink>
          <div style={{ flexGrow: 1 }} />
          {isUnauthenticated ? (
            <IconButton edge="end" onClick={handleLogoutMenuOpen} color="inherit">
              <Avatar />
            </IconButton>
          ) : (
            <>
              <IconButton
                component={ReactRouterDomLink}
                to={routesConfig.cart.path}
                sx={{ color: "white", mr: 3 }}>
                <CartIcon />
              </IconButton>
              <IconButton edge="end" onClick={handleLogoutMenuOpen} color="inherit">
                <HeaderAvatar />
              </IconButton>
              <LogoutMenu
                open={isLogoutMenuOpen}
                anchorEl={anchorElUser}
                onClose={handleLogoutMenuClose}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
