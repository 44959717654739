import {
  type Palette,
  type SxProps,
  Typography,
  type TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import statutMap, { type StatutAssociation } from "constants/SatutMap";
import type Statut from "constants/Statut";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { type ReactElement, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import ApplicationRoles from "constants/ApplicationRoles";
import { useUser } from "@sdeapps/react-core";

interface StatutChipProps extends TypographyProps {
  statut: Statut;
  isSmall?: boolean;
  sx?: SxProps;
}

const defaultChipAssociation: StatutAssociation = {
  color: "grey" as keyof Palette,
  label: "???",
  smallLabel: "???",
  icon: ErrorOutlineOutlinedIcon,
};

function StatutChip({
  statut,
  variant = "body2",
  isSmall = false,
  sx,
  ...typographyProps
}: StatutChipProps): ReactElement {
  const [chipAssociation, setChipAssociation] = useState<StatutAssociation>(defaultChipAssociation);

  const theme = useTheme();
  const user = useUser();

  const sizeIsSmall = useMediaQuery(theme.breakpoints.down("md"));

  const isSmallScreen = isSmall || sizeIsSmall;
  // on debounce isSmallScreen pour une question de performance, trop de rerenders inutiles sinon
  const [debouncedIsSmallScreen] = useDebounce(isSmallScreen, 500);

  useEffect(() => {
    setChipAssociation(statutMap.get(statut) ?? defaultChipAssociation);
  }, [statut]);

  return (
    <Typography
      variant={variant}
      color={`${chipAssociation.color}.main`}
      {...typographyProps}
      sx={{
        backgroundColor: `${chipAssociation.color}.light`,
        paddingX: 1,
        paddingY: 0.5,
        fontWeight: 600,
        borderRadius: 1,
        display: "inline-block",
        ...sx,
      }}>
      {(user.isRoles(ApplicationRoles.SSTMANAGER) || user.isRoles(ApplicationRoles.STOREMANAGER)) &&
      debouncedIsSmallScreen
        ? chipAssociation.smallLabel
        : chipAssociation.label}
    </Typography>
  );
}

export default StatutChip;
