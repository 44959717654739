import {
  Button,
  MenuItem,
  type Palette,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NumberInput from "components/atoms/inputs/NumberInput";
import type Article from "models/Article";
import StatutButtonGroup from "components/atoms/buttons/StatutButtonGroup/StatutButtonGroup";
import Statut from "constants/Statut";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";
import { type ReactElement } from "react";

type CommandeValidationTableProps = {
  articlesList: Array<CommandeArticleFormModel>;
  setArticlesList: (c: Array<CommandeArticleFormModel>) => void;
  catalogue: Array<Article>;
  statutOptions: Array<{
    statut: Statut;
    color: keyof Palette;
    label: string;
    smallLabel: string;
  }>;
};

function CommandeValidationTable({
  articlesList,
  setArticlesList,
  catalogue,
  statutOptions,
}: CommandeValidationTableProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  function validateAll(): void {
    setArticlesList(
      articlesList.map((value) => {
        return {
          ...value,
          statutArticle: Statut.EN_PREPARATION,
        };
      })
    );
  }

  function refuseAll(): void {
    setArticlesList(
      articlesList.map((value) => {
        return {
          ...value,
          statutArticle: Statut.REFUSE,
        };
      })
    );
  }

  function updateQuantite(newQuantite: number, articleIndex: number): void {
    const tempArticleList = [...articlesList];
    tempArticleList[articleIndex].quantite = newQuantite;
    setArticlesList(tempArticleList);
  }

  function updateTaille(newTaille: string, articleIndex: number): void {
    const tempArticleList = [...articlesList];
    tempArticleList[articleIndex].taille = newTaille;
    setArticlesList(tempArticleList);
  }

  function updateStatut(newStatut: Statut | undefined, articleIndex: number): void {
    const tempArticleList = [...articlesList];
    tempArticleList[articleIndex].statutArticle = newStatut;
    setArticlesList(tempArticleList);
  }

  return (
    <TableContainer sx={{ width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>
                <strong>Article</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Qté</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Taille</strong>
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Button
                sx={{ m: 0.5 }}
                size={isSmallScreen ? "small" : undefined}
                variant="contained"
                color="success"
                onClick={validateAll}>
                Tout Valider
              </Button>
              <Button
                sx={{ m: 0.5 }}
                size={isSmallScreen ? "small" : undefined}
                variant="contained"
                color="error"
                onClick={refuseAll}>
                Tout Refuser
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articlesList.map((article, i) => {
            return (
              <TableRow
                key={article.commandeArticleNumber}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                <TableCell>
                  <Typography>{article.articleLabel}</Typography>
                </TableCell>
                <TableCell>
                  <NumberInput
                    size="small"
                    value={article.quantite}
                    onChange={(newQuantite) => {
                      updateQuantite(newQuantite, i);
                    }}
                    sx={{ maxWidth: "70px" }}
                    minValue={1}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={article.taille}
                    onChange={(event) => {
                      updateTaille(event.target.value, i);
                    }}
                    fullWidth
                    size="small">
                    {catalogue
                      .find((v: Article) => v.id === article.articleId)
                      ?.tailles?.map((t) => (
                        <MenuItem key={t} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
                <TableCell align="right">
                  <StatutButtonGroup
                    onChangeStatut={(newStatut?: Statut) => {
                      updateStatut(newStatut, i);
                    }}
                    value={article.statutArticle}
                    statutOptions={statutOptions}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CommandeValidationTable;
