import {
  ButtonGroup,
  type Palette,
  Typography,
  type TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type Statut from "constants/Statut";
import { type colorPaletteKey } from "providers/EpicThemeProvider";
import { useDebounce } from "use-debounce";
import StatutButton from "./StatutButton";
import { type ReactElement } from "react";

interface StatutButtonGroupProps extends TypographyProps {
  statutOptions: Array<{
    statut: Statut;
    color: keyof Palette;
    label: string;
    smallLabel: string;
  }>;
  onChangeStatut: (newStatut?: Statut | undefined) => void;
  value: Statut | undefined;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  smallLabels?: boolean;
}

function StatutButtonGroup({
  statutOptions,
  onChangeStatut,
  value,
  disabled = false,
  size = "medium",
  smallLabels = false,
  ...typographyProps
}: StatutButtonGroupProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  // on debounce isSmallScreen pour une question de performance, trop de rerenders inutiles sinon
  const [debouncedIsSmallScreen] = useDebounce(isSmallScreen, 500);

  return (
    <ButtonGroup variant="outlined" size={debouncedIsSmallScreen ? "small" : size}>
      {statutOptions.map((statutOption) => {
        return (
          <StatutButton
            key={statutOption.statut}
            color={statutOption.color as colorPaletteKey}
            onClick={() => {
              if (value === statutOption.statut) {
                onChangeStatut(undefined);
              } else {
                onChangeStatut(statutOption.statut);
              }
            }}
            checked={value === statutOption.statut}
            label={
              <Typography {...typographyProps}>
                {debouncedIsSmallScreen || smallLabels
                  ? statutOption.smallLabel
                  : statutOption.label}
              </Typography>
            }
            disabled={disabled}
          />
        );
      })}
    </ButtonGroup>
  );
}

export default StatutButtonGroup;
