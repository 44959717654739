import { createSlice } from "@reduxjs/toolkit";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";

interface CartParameters {
  payload: CommandeArticleCartModel;
  type: string;
}

const cartSlice = createSlice({
  name: "cart",
  initialState: [] as Array<CommandeArticleCartModel>,
  reducers: {
    addArticle: (state, { payload }: CartParameters) => {
      const foundArticle = state.find(
        (commandeArticleFormModel) =>
          commandeArticleFormModel.articleId === payload.articleId &&
          commandeArticleFormModel.taille === payload.taille
      );

      if (foundArticle != null) {
        const i = state.indexOf(foundArticle);
        state[i].quantite += payload.quantite;
        if (state[i].quantite > state[i].quantiteMax) {
          state[i].quantite = state[i].quantiteMax;
        }
      } else {
        state.push(payload);
      }
      return state;
    },
    deleteArticle: (state, { payload }: CartParameters) => {
      return state.filter((p) => p.articleId !== payload.articleId || p.taille !== payload.taille);
    },
    deleteArticles: (state) => {
      return [];
    },
  },
});

export const { addArticle, deleteArticle, deleteArticles } = cartSlice.actions;

export default cartSlice.reducer;
