import { type SvgIconComponent } from "@mui/icons-material";
import AuditionIcon from "icons/categories/AuditionIcon";
import CorpsIcon from "icons/categories/CorpsIcon";
import GenouxIcon from "icons/categories/GenouxIcon";
import MainIcon from "icons/categories/MainIcon";
import PiedsIcon from "icons/categories/PiedsIcon";
import RespiratoireIcon from "icons/categories/RespiratoireIcon";
import TeteIcon from "icons/categories/TeteIcon";
import YeuxIcon from "icons/categories/YeuxIcons";

export interface Categorie {
  dataBaseLabel: string;
  printLabel: string;
  icon: SvgIconComponent;
}

const categories: Array<Categorie> = [
  { dataBaseLabel: "Protection Auditive", printLabel: "Auditive", icon: AuditionIcon },
  { dataBaseLabel: "Protection de tête", printLabel: "Tête", icon: TeteIcon },
  { dataBaseLabel: "Protection des Mains", printLabel: "Mains", icon: MainIcon },
  { dataBaseLabel: "Protection des Pieds", printLabel: "Pieds", icon: PiedsIcon },
  {
    dataBaseLabel: "Protection des Voies Respiratoires",
    printLabel: "Masques",
    icon: RespiratoireIcon,
  },
  { dataBaseLabel: "Protection des Yeux", printLabel: "Yeux", icon: YeuxIcon },
  { dataBaseLabel: "Protection du Corps", printLabel: "Corps", icon: CorpsIcon },
  { dataBaseLabel: "Protection genoux", printLabel: "Genoux", icon: GenouxIcon },
];

export default categories;
