import Statut from "constants/Statut";

const SST_DEFAULT_STATUTS_FILTER: Array<Statut> = [Statut.EN_ATTENTE];

const STORE_MANAGER_DEFAULT_STATUTS_FILTER: Array<Statut> = [
  Statut.COMMANDE,
  Statut.PRET_EN_MAGASIN,
  Statut.EN_PREPARATION,
];

const commandesFiltersService = {
  SST_DEFAULT_STATUTS_FILTER,
  STORE_MANAGER_DEFAULT_STATUTS_FILTER,
};
export default commandesFiltersService;
