import { Box } from "@mui/material";
import { type ReactElement, memo } from "react";

type SquareImageProps = {
  image: string;
  width?: string;
  height?: string;
  withBackground?: boolean;
};

const SquareImage = memo(function SquareImage({
  image,
  width = "140px",
  height = width,
  withBackground = true,
}: SquareImageProps): ReactElement {
  return (
    <Box sx={{ position: "relative", width: { width }, height: { height } }}>
      {withBackground && (
        <Box
          sx={{
            borderRadius: "100%",
            backgroundColor: "#E0F3F8",
            backgroundPosition: "center",
            width: "80%",
            height: "80%",
            margin: "10%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url("${image ?? ""}")`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </Box>
  );
});

export default SquareImage;
