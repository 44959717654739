import { createSvgIcon } from "@mui/material";

const ChatIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.75 8.25H17V4.5C17 3.13281 15.8672 2 14.5 2H3.25C1.84375 2 0.75 3.13281 0.75 4.5V10.75C0.75 12.1562 1.84375 13.25 3.25 13.25H4.5V15.2812C4.5 15.5938 4.69531 15.75 4.96875 15.75C5.04688 15.75 5.125 15.75 5.24219 15.6719L8.25 13.9922V17C8.25 18.4062 9.34375 19.5 10.75 19.5H14.5L18.7188 21.9219C18.8359 22 18.9141 22 19.0312 22C19.2656 22 19.5 21.8438 19.5 21.5312V19.5H20.75C22.1172 19.5 23.25 18.4062 23.25 17V10.75C23.25 9.38281 22.1172 8.25 20.75 8.25ZM4.5 11.375H3.25C2.89844 11.375 2.625 11.1016 2.625 10.75V4.5C2.625 4.1875 2.89844 3.875 3.25 3.875H14.5C14.8125 3.875 15.125 4.1875 15.125 4.5V10.75C15.125 11.1016 14.8125 11.375 14.5 11.375H8.99219L8.5625 11.6484L6.375 12.8984V11.375H4.5ZM21.375 17C21.375 17.3516 21.0625 17.625 20.75 17.625H17.625V19.1484L15.3984 17.8984L14.9688 17.625H10.75C10.3984 17.625 10.125 17.3516 10.125 17V13.25H14.5C15.8672 13.25 17 12.1562 17 10.75V10.125H20.75C21.0625 10.125 21.375 10.4375 21.375 10.75V17Z"
      fill="currentColor"
    />
  </svg>,
  "ChatIcon"
);

export default ChatIcon;
