import {
  type SxProps,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { type ReactElement } from "react";

function ArticleLineHeader(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const bodyVariant: { variant: "body1" | "body2" } = {
    variant: isSmallScreen ? "body2" : "body1",
  };

  const padding05: { sx: SxProps } = {
    sx: { paddingX: 0.5, paddingY: 1, verticalAlign: "top" },
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell {...padding05}>
          <Typography {...bodyVariant} sx={{ fontWeight: 600 }}>
            Qté
          </Typography>
        </TableCell>
        <TableCell {...padding05}>
          <Typography {...bodyVariant} sx={{ fontWeight: 600 }}>
            Article
          </Typography>
        </TableCell>
        <TableCell {...padding05} align="right">
          <Typography {...bodyVariant} sx={{ fontWeight: 600 }}>
            Taille
          </Typography>
        </TableCell>
        <TableCell align="right" sx={{ paddingX: 0.5 }}>
          <Typography {...bodyVariant} sx={{ fontWeight: 600 }}>
            État
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ArticleLineHeader;
