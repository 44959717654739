import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import ArticleListStore from "components/molecules/ArticleListStore";
import PageTitle from "components/molecules/PageTitle";
import SubTitleCommande from "components/molecules/SubTitleCommande";
import type Commande from "models/Commande";
import { type User } from "@sdeapps/react-core";
import { type ReactElement } from "react";
import SaveIcon from "@mui/icons-material/Save";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";

interface StoreManagerCommandePageTemplateProps {
  demandeur: User | undefined;
  articlesList: Array<CommandeArticleFormModel>;
  updateCommande: () => Promise<void>;
  isSending: boolean;
  setArticlesList: (arg: Array<CommandeArticleFormModel>) => void;
  commentaire: string;
  setCommentaire: (value: string) => void;
  commande: Commande;
}

function StoreManagerCommandePageTemplate({
  demandeur,
  articlesList,
  updateCommande,
  isSending,
  setArticlesList,
  commentaire,
  setCommentaire,
  commande,
}: StoreManagerCommandePageTemplateProps): ReactElement {
  return (
    <>
      <PageTitle title={demandeur?.displayName ?? "???"}>
        <SubTitleCommande demandeur={demandeur} commande={commande}>
          <LoadingButton
            variant="contained"
            disabled={
              articlesList.find((value) => value.statutArticle === undefined) != null || isSending
            }
            onClick={() => {
              void updateCommande();
            }}
            endIcon={<SaveIcon />}
            loading={isSending}>
            Mettre à jour la commande
          </LoadingButton>
        </SubTitleCommande>
      </PageTitle>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ArticleListStore articlesList={articlesList} setArticlesList={setArticlesList} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={commentaire}
              onChange={(e) => {
                setCommentaire(e.target.value);
              }}
              multiline
              fullWidth
              label="Commentaire du Magasinier"
              placeholder="Saisissez votre message ici..."
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default StoreManagerCommandePageTemplate;
