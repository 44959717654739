import { Chip } from "@mui/material";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";
import { type ReactElement } from "react";
import { useSelector } from "react-redux";
import { type StoreProps } from "store/store";

type CartChipProps = {
  color?: "primary" | "default" | "secondary" | "error" | "info" | "success" | "warning";
  size?: "small" | "medium";
};

function CartChip({ color = "primary", size = "small" }: CartChipProps): ReactElement {
  const cart = useSelector<StoreProps, Array<CommandeArticleCartModel>>((state) => state?.cart);

  if (cart.length === 0) return <></>;

  return <Chip size={size} label={cart.length} color={color} />;
}

export default CartChip;
