import imageCompression from "browser-image-compression";

async function getBase64DataFromFileSelection(
  e: React.ChangeEvent<HTMLInputElement>
): Promise<string> {
  type FileEventTarget = EventTarget & { files: FileList };
  if ((e.target as HTMLInputElement)?.files?.length != null) {
    const eTarget = e.target as FileEventTarget;
    const imageFile = eTarget.files[0];

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };

    try {
      const base64CompressedImage = await imageCompression.getDataUrlFromFile(
        await imageCompression(imageFile, options)
      );
      e.target.value = "";
      return base64CompressedImage;
    } catch (error: any) {
      e.target.value = "";
      throw new Error("Error occured during compression !", error);
    }
  }
  /* On nettoie la sélection actuelle de l'input pour la prochaine sélection */
  e.target.value = "";
  throw new Error("Event target has no file !");
}

const imageUtil = {
  getBase64Data: getBase64DataFromFileSelection,
};
export default imageUtil;
