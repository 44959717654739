import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  CircularProgress,
  type SxProps,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import BarcodeTypography from "components/atoms/BarcodeTypography";
import StatutButtonGroup from "components/atoms/buttons/StatutButtonGroup/StatutButtonGroup";
import { magasinierArticleStatutOptions } from "constants/SatutMap";
import type Statut from "constants/Statut";
import type CommandeArticleTri from "models/CommandeArticleTri";
import type PatchData from "models/PatchData";
import { dateUtil } from "@sdeapps/react-core";
import type { User } from "@sdeapps/react-core";
import commandesService from "services/commandesService";

type TriByArticleLineProps = {
  article: CommandeArticleTri;
  agent?: User;
  disableOnSending?: boolean;
};

function TriByArticleLine({
  article,
  agent,
  disableOnSending = true,
}: TriByArticleLineProps): ReactNode {
  const [workingArticle, setWorkingArticle] = useState<CommandeArticleTri>();
  const [isSending, setIsSending] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setWorkingArticle(article);
    setIsSending(false);
  }, [article]);

  function onStatutChange(newStatut?: Statut): void {
    if (newStatut == null) return;
    setStatut(newStatut);
    void sendStatutUpdate(newStatut);
  }

  function setStatut(newStatut: Statut): void {
    if (workingArticle == null) return;
    setWorkingArticle({ ...workingArticle, statutArticle: newStatut });
  }

  async function sendStatutUpdate(newStatut: Statut): Promise<void> {
    if (workingArticle == null) {
      console.error("workingArticle is null (PreparationByArticleLine updateCommande)");
      return;
    }
    setIsSending(true);

    const statutsPatches: Array<PatchData> = [
      {
        op: "replace",
        path: `/articles/${workingArticle.commandeArticleNumber}/statutArticle`,
        value: newStatut,
      },
    ];

    try {
      if (statutsPatches != null) {
        const updatedCommand = await commandesService.patchCommande(
          workingArticle.commandeId,
          statutsPatches
        );
        setWorkingArticle({
          ...updatedCommand.articles.find(
            (a) => a.commandeArticleNumber === article.commandeArticleNumber
          )!,
          magasinDeRetrait: updatedCommand.magasinDeRetrait,
        });
      } else throw new Error();
      // TODO error handling
    } catch (error) {
      // TODO error handling
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const greyIfSending: SxProps = {
    color: disableOnSending && isSending ? grey[500] : undefined,
  };

  if (workingArticle == null) {
    return null;
  }

  function getStatutButtonCellWidth(): string {
    if (isLargeScreen) {
      if (isMediumScreen) {
        return "290px";
      }
      return "335px";
    }
    return "375px";
  }

  return (
    <TableRow
      sx={{ ".MuiTableCell-root": { paddingX: 1 } }}
      key={`${article.commandeId}${article.commandeArticleNumber}`}>
      <TableCell>
        <Typography sx={greyIfSending}>
          {dateUtil.format(workingArticle.dateCommande, "dd/MM/yyyy")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={greyIfSending}>{agent?.displayName ?? "???"}</Typography>
      </TableCell>
      <TableCell>
        <BarcodeTypography sx={greyIfSending} variant="barcode1" matricule={agent?.employeeId} />
      </TableCell>
      <TableCell>
        <Typography sx={greyIfSending}>{workingArticle.magasinDeRetrait}</Typography>
      </TableCell>
      <TableCell>
        <Typography sx={greyIfSending}>{workingArticle.quantite}</Typography>
      </TableCell>
      <TableCell>
        <Typography sx={greyIfSending}>
          {workingArticle.taille.toLowerCase() === "taille unique" ? "TU" : workingArticle.taille}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          "&.MuiTableCell-root": {
            width: getStatutButtonCellWidth(),
            paddingX: 0,
          },
        }}>
        <StatutButtonGroup
          onChangeStatut={onStatutChange}
          value={workingArticle.statutArticle}
          statutOptions={magasinierArticleStatutOptions}
          disabled={disableOnSending && isSending}
          size={isLargeScreen ? "small" : undefined}
          variant={isMediumScreen ? "body2" : undefined}
          smallLabels
        />
      </TableCell>
      <TableCell sx={{ width: "46px" }}>
        {disableOnSending && isSending && <CircularProgress size={30} />}
      </TableCell>
    </TableRow>
  );
}
export default TriByArticleLine;
