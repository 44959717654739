import {
  Checkbox,
  Chip,
  FormControl,
  type FormControlProps,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { type ReactElement } from "react";

interface TaillesInputProps extends FormControlProps {
  selectedTailles: Array<string>;
  setSelectedTailles: (t: Array<string>) => void;
  availableTailles: Array<string>;
}

function TaillesInput({
  selectedTailles,
  setSelectedTailles,
  availableTailles,
  ...formControlProps
}: TaillesInputProps): ReactElement {
  return (
    <FormControl fullWidth size="small" {...formControlProps}>
      <InputLabel>Taille(s)</InputLabel>
      <Select
        multiple
        value={selectedTailles}
        onChange={(event) => {
          setSelectedTailles(event.target.value as Array<string>);
        }}
        input={<OutlinedInput label="Taille(s)" />}
        renderValue={(selected) =>
          selected.map((s) => {
            return <Chip key={s} variant="outlined" label={s} size="small" />;
          })
        }>
        {(availableTailles ?? []).map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selectedTailles.includes(name)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TaillesInput;
