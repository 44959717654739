import { createSvgIcon } from "@mui/material";

const MainIcon = createSvgIcon(
  <svg viewBox="0 0 84 84">
    <path
      fill="currentColor"
      d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
    />
    <path
      d="M42 5.25C21.7 5.25 5.25 21.7 5.25 42C5.25 62.3 21.7 78.75 42 78.75C62.3 78.75 78.75 62.3 78.75 42C78.75 21.7 62.3 5.25 42 5.25ZM27.03 48.81L16.66 42.76C15.26 41.95 14.79 40.15 15.61 38.76C16.43 37.36 18.22 36.89 19.61 37.71L23.78 40.14L19.4 28.5C18.91 27.19 19.57 25.73 20.88 25.23C22.19 24.74 23.64 25.4 24.14 26.7L23.25 24.34C22.76 23.03 23.42 21.57 24.73 21.07C26.04 20.57 27.5 21.24 28 22.55L28.44 23.73C27.95 22.42 28.61 20.96 29.92 20.47C31.23 19.98 32.7 20.64 33.19 21.95L34.53 25.51C34.04 24.2 34.7 22.74 36.01 22.25C37.32 21.76 38.78 22.42 39.28 23.73L40.28 26.39C39.32 26.76 38.51 27.43 37.98 28.33C37.36 29.39 37.2 30.62 37.51 31.8L40.39 42.76L35.07 52.14C34.03 52.23 33.1 52.88 32.65 53.85C32.49 54.2 32.42 54.56 32.4 54.92L29.06 56.18L27.03 48.82V48.81ZM31.17 63.37C30.51 63.62 29.78 63.29 29.54 62.63C29.29 61.97 29.62 61.24 30.28 60.99L30.39 60.95L29.6 58.09L33.01 56.8C33.29 57.17 33.66 57.49 34.12 57.7L39.6 60.18L31.17 63.35V63.37ZM56.64 63.32C57.28 63.61 57.56 64.36 57.27 65C56.98 65.64 56.23 65.92 55.59 65.63L34.79 56.2C34.15 55.91 33.87 55.16 34.16 54.52C34.45 53.88 35.2 53.6 35.84 53.89L35.94 53.94L37.4 51.36L57.49 60.46L56.52 63.26L56.62 63.31L56.64 63.32ZM60.72 51.24L58.16 58.58L38.39 49.63L42.16 42.99L39.11 31.37C38.7 29.81 39.63 28.21 41.19 27.8C42.75 27.39 44.36 28.32 44.77 29.89L46 34.56L51.13 23.23C51.71 21.95 53.21 21.39 54.49 21.97C55.76 22.55 56.33 24.04 55.76 25.32L56.8 23.03C57.38 21.75 58.88 21.19 60.16 21.77C61.44 22.35 62 23.85 61.42 25.13L60.9 26.28C61.48 25.01 62.98 24.45 64.25 25.02C65.53 25.6 66.09 27.1 65.51 28.38L63.94 31.84C64.52 30.57 66.02 30 67.3 30.58C68.58 31.16 69.14 32.66 68.56 33.94L60.71 51.27L60.72 51.24Z"
      fill="white"
    />
  </svg>,
  "MainIcon"
);
export default MainIcon;
