import { useEffect, useState } from "react";
import type { MutableRefObject } from "react";

/**
 * Custom Hook qui prend la ref d'un élément de la page et renvoie un booléen, true si l'élément
 * est actuellement visible à l'écran, false autrement.
 * @param ref ref de l'élement à détécter
 * @returns true ou false
 */
export function useIsVisible(ref: MutableRefObject<any>): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
