import { createSvgIcon } from "@mui/material";

const GenouxIcon = createSvgIcon(
  <svg viewBox="0 0 42 42">
    <circle cx="21" cy="21" r="21" fill="currentColor" />
    <circle cx="21.005" cy="21.005" r="18.375" fill="white" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4713 6.44752C21.2413 6.2948 20.931 6.35751 20.7782 6.58758C20.6255 6.81765 20.6882 7.12796 20.9183 7.28068C21.3845 7.59015 21.8941 7.94062 22.4326 8.32497C20.281 9.35098 18.773 10.3351 17.6549 11.5192C16.5013 12.741 15.7928 14.1438 15.1956 15.9398L8.02239 13.5488C7.76041 13.4614 7.47725 13.603 7.38993 13.865C7.30261 14.127 7.44419 14.4101 7.70616 14.4974L15.3509 17.0457L23.2609 19.9794C21.9564 21.0583 21.1447 22.1752 20.6141 23.4361C20.0002 24.8947 19.7763 26.5186 19.555 28.4063C19.3472 30.1781 19.349 32.2247 19.461 34.6591C19.4737 34.9349 19.7077 35.1482 19.9835 35.1355C20.2594 35.1228 20.4727 34.8889 20.46 34.6131C20.3671 32.5963 20.3526 30.8822 20.4655 29.3878C21.6594 30.2162 22.8099 30.7573 24.206 30.9392C25.6597 31.1285 27.3353 30.9223 29.5508 30.3249C29.3146 31.0412 29.1491 31.756 29.0897 32.4979C29.0677 32.7731 29.273 33.0141 29.5482 33.0361C29.8235 33.0582 30.0645 32.8529 30.0865 32.5776C30.1605 31.653 30.426 30.7496 30.8231 29.7619C31.123 29.0162 31.4916 28.2367 31.9028 27.3676L31.9028 27.3675L31.9563 27.2543C32.387 26.3438 32.8578 25.3425 33.3247 24.2118C33.6421 23.4431 33.9579 22.614 34.2591 21.7105C34.5782 20.7533 34.411 19.7026 33.975 18.6587C33.5377 17.6117 32.8086 16.518 31.9181 15.4338C29.7215 12.7592 26.4412 10.0211 23.7125 8.01716C22.9067 7.42531 22.1459 6.8953 21.4713 6.44752ZM29.7268 18.5803C30.0024 17.8802 30.539 17.1448 31.4421 16.4384C32.1569 17.3513 32.7127 18.2312 33.0523 19.0441C33.4386 19.9692 33.5237 20.7546 33.3104 21.3943C33.0649 22.1308 32.8092 22.8172 32.5503 23.462C31.2493 23.1702 30.0725 22.1392 29.6513 20.8425C29.4247 20.1448 29.4162 19.3695 29.7268 18.5803Z"
    />
  </svg>,
  "GenouxIcon"
);

export default GenouxIcon;
