const typeKeys = [
  "Taille unique",
  "Taille Tx",
  "Taille vêtement",
  "Taille Chaussures",
  "Taille Combinaison",
] as const;
type TypeTaille = Record<(typeof typeKeys)[number], Array<string>>;

export const typesTailles: TypeTaille = {
  "Taille unique": ["Taille unique"],
  "Taille Tx": ["T7", "T8", "T9", "T9 1/2", "T10", "T11", "T12"],
  "Taille vêtement": ["S", "M", "L", "XL", "XXL", "3XL", "4XL", "5XL"],
  "Taille Chaussures": [
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
  ],
  "Taille Combinaison": [
    "0 : XS",
    "1 : S",
    "2 : M",
    "3 : L",
    "4 : XL",
    "5 : 2XL",
    "6 : 3XL",
    "7 : 4XL",
    "8 : 5XL",
  ],
};

export const allTaillesMap = new Map<string, Array<string>>(Object.entries(typesTailles));
