import { type SxProps, TextField } from "@mui/material";
import { type ChangeEvent, type ReactElement, useCallback, useState } from "react";

interface NumberInputProps {
  value: number | undefined;
  onChange: (a: number) => void;
  label?: string;
  placeholder?: string;
  size?: "small" | "medium";
  fullWidth?: boolean;
  minValue?: number;
  width?: string;
  sx?: SxProps;
}

function NumberInput({
  value,
  onChange,
  label = "",
  placeholder = "",
  size = "medium",
  fullWidth = false,
  minValue = 0,
  width = "100px",
  sx,
}: NumberInputProps): ReactElement {
  const [inputValue, setInputValue] = useState<string>(value?.toString() ?? "");

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      let parsed = parseInt(/\d+/.exec(event?.target?.value)?.shift() ?? minValue.toString(), 10);

      if (Number.isNaN(parsed)) {
        setInputValue("");
        onChange(minValue);
        return;
      }
      if (parsed < minValue) {
        parsed = minValue;
      }
      setInputValue(parsed.toString());
      onChange(parsed);
    },
    [minValue, setInputValue, onChange]
  );

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      type="number"
      value={inputValue}
      size={size}
      onChange={handleChange}
      fullWidth={fullWidth}
      sx={{
        ...(fullWidth ? {} : { width }),
        ...sx,
      }}
    />
  );
}

export default NumberInput;
