import { Typography, type TypographyProps, useMediaQuery, useTheme } from "@mui/material";
import { type ElementType, type ReactElement } from "react";

interface BarcodeTypographyProps extends TypographyProps {
  variant: "barcode1" | "barcode2";
  matricule: string | undefined;
  component?: ElementType;
}

function BarcodeTypography({
  variant,
  matricule,
  component,
  ...props
}: BarcodeTypographyProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  if (matricule == null) {
    return <Typography>Pas de matricule</Typography>;
  }

  return (
    <Typography
      fontSize={isSmallScreen ? "2.5rem" : "3rem"}
      variant={variant}
      {...(component != null ? { component } : {})}
      {...props}>
      *0000{matricule}CO*
    </Typography>
  );
}

export default BarcodeTypography;
