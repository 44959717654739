import { forwardRef, type Ref } from "react";
import imageUtil from "utils/imageUtil";

type HiddenFileInputProps = {
  onNewBase64Data: (base64data: string) => void;
  onStartLoading: () => void;
  onError: (error: any) => void;
};

/* Input caché, artificiellement clické pour permettre de sélectionner une photo */
const HiddenFileInput = forwardRef(function HiddenFileInput(
  { onNewBase64Data, onStartLoading, onError }: HiddenFileInputProps,
  ref: Ref<HTMLInputElement>
) {
  function handleImageChange(event: React.ChangeEvent<HTMLInputElement>): void {
    void handleImageChangeAsync(event);
  }

  async function handleImageChangeAsync(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    onStartLoading();
    try {
      onNewBase64Data(await imageUtil.getBase64Data(event));
    } catch (error) {
      onError(error);
    }
  }

  return (
    <input
      type="file"
      accept="image/jpeg, image/png, image/tiff, image/webp"
      ref={ref}
      onChange={handleImageChange}
      style={{ display: "none" }}
    />
  );
});

export default HiddenFileInput;
