import Statut from "./Statut";
import LivreIcon from "icons/statuts/LivreIcon";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { type SvgIconComponent } from "@mui/icons-material";
import { type Palette } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RestoreIcon from "@mui/icons-material/Restore";

export interface StatutAssociation {
  color: keyof Palette;
  label: string;
  smallLabel: string;
  icon: SvgIconComponent;
}

const statutMap = new Map<Statut, StatutAssociation>();

statutMap.set(Statut.EN_ATTENTE, {
  color: "aTraiter",
  label: "En attente de validation",
  smallLabel: "En attente",
  icon: ErrorOutlineOutlinedIcon,
});

statutMap.set(Statut.EN_PREPARATION, {
  color: "preparation",
  label: "En préparation",
  smallLabel: "Préparation",
  icon: ErrorOutlineOutlinedIcon,
});

statutMap.set(Statut.LIVRE, {
  color: "livre",
  label: "Livré",
  smallLabel: "Livré",
  icon: LivreIcon,
});

statutMap.set(Statut.PRET_EN_MAGASIN, {
  color: "success",
  label: "Prêt en magasin",
  smallLabel: "Prêt",
  icon: CheckIcon,
});

statutMap.set(Statut.REFUSE, {
  color: "refuse",
  label: "Refusé",
  smallLabel: "Refusé",
  icon: CloseIcon,
});

statutMap.set(Statut.COMMANDE, {
  color: "commande",
  label: "Commandé",
  smallLabel: "Commandé",
  icon: RestoreIcon,
});

statutMap.set(Statut.ANNULE, {
  color: "refuse",
  label: "Annulé",
  smallLabel: "Annulé",
  icon: CloseIcon,
});

const magasinierArticleStatutOptions = [
  {
    statut: Statut.EN_PREPARATION,
    ...statutMap.get(Statut.EN_PREPARATION)!,
  },
  { statut: Statut.COMMANDE, ...statutMap.get(Statut.COMMANDE)! },
  { statut: Statut.PRET_EN_MAGASIN, ...statutMap.get(Statut.PRET_EN_MAGASIN)! },
  { statut: Statut.ANNULE, ...statutMap.get(Statut.ANNULE)! },
  { statut: Statut.LIVRE, ...statutMap.get(Statut.LIVRE)! },
];

export default statutMap;
export { magasinierArticleStatutOptions };
