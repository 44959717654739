import { LoadingButton } from "@mui/lab";
import { Grid, type Palette, Paper, TextField } from "@mui/material";
import { Container } from "@mui/system";
import PageTitle from "components/molecules/PageTitle";
import SubTitleCommande from "components/molecules/SubTitleCommande";
import CommandeValidationTable from "components/molecules/tables/CommandeValidationTable";
import CommandeHistory from "components/organisms/CommandeHistory";
import Statut from "constants/Statut";
import type Article from "models/Article";
import type CommandeArticleHistory from "models/CommandeArticleHistory";
import { type User } from "@sdeapps/react-core";
import { type ReactElement } from "react";
import SaveIcon from "@mui/icons-material/Save";
import type Commande from "models/Commande";
import type CommandeArticleFormModel from "models/CommandeArticleFormModel";

const validationStatutOptions: Array<{
  statut: Statut;
  color: keyof Palette;
  label: string;
  smallLabel: string;
}> = [
  {
    statut: Statut.EN_PREPARATION,
    color: "success",
    label: "Valider",
    smallLabel: "Valider",
  },
  {
    statut: Statut.REFUSE,
    color: "error",
    label: "Refuser",
    smallLabel: "Refuser",
  },
];

interface ValidatorCommandeTemplateProps {
  demandeur: User | undefined;
  articlesList: Array<CommandeArticleFormModel>;
  updateCommande: () => Promise<void>;
  isSending: boolean;
  historyCommandes: Array<CommandeArticleHistory>;
  setArticlesList: (arg: Array<CommandeArticleFormModel>) => void;
  articles: Array<Article>;
  commentaire: string;
  setCommentaire: (value: string) => void;
  commande: Commande;
}

function ValidatorCommandeTemplate({
  demandeur,
  articlesList,
  updateCommande,
  isSending,
  historyCommandes,
  setArticlesList,
  articles,
  commentaire,
  setCommentaire,
  commande,
}: ValidatorCommandeTemplateProps): ReactElement {
  return (
    <>
      <PageTitle title={demandeur?.displayName ?? "???"}>
        <SubTitleCommande demandeur={demandeur} commande={commande}>
          <LoadingButton
            variant="contained"
            disabled={
              articlesList.find(
                (value) => value.statutArticle == null || value.statutArticle === Statut.EN_ATTENTE
              ) != null || isSending
            }
            onClick={() => {
              void updateCommande();
            }}
            startIcon={<SaveIcon />}
            loading={isSending}>
            Mettre à jour la commande
          </LoadingButton>
        </SubTitleCommande>
      </PageTitle>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7} xl={8}>
            <Paper item container sx={{ p: 2 }} component={Grid} variant="outlined">
              <Grid item container xs={12}>
                <CommandeValidationTable
                  articlesList={articlesList}
                  setArticlesList={setArticlesList}
                  catalogue={articles}
                  statutOptions={validationStatutOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={commentaire}
                  onChange={(e) => {
                    setCommentaire(e.target.value);
                  }}
                  multiline
                  fullWidth
                  label="Commentaire du Validateur"
                  placeholder="Saisissez votre message ici..."
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={5} xl={4}>
            <CommandeHistory historyCommandes={historyCommandes} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ValidatorCommandeTemplate;
