import { Container, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CartTable from "components/molecules/tables/CartTable";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { deleteArticles } from "reducers/cartReducer";
import { type StoreProps } from "store/store";
import type CommandeFormModel from "models/CommandeFormModel";
import { type ReactElement, useEffect, useState } from "react";
import commandesService from "services/commandesService";
import type CommandeArticleCartModel from "models/CommandeArticleCartModel";
import LoadingButton from "components/atoms/buttons/LoadingButton";
import { type SnackbarKey, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ApplicationRoles from "constants/ApplicationRoles";
import { type User, useUser, dateUtil, Authorization } from "@sdeapps/react-core";
import graphService from "services/graphService";
import AgentSelect from "components/atoms/AgentSelect";
import MagasinDeRetrait from "constants/MagasinDeRetrait";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "config/app-config";
import PageTitle from "components/molecules/PageTitle";
import MagasinDeRetraitInput from "components/atoms/inputs/MagasinDeRetraitInput";

function CartPage(): ReactElement {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [magasin, setMagasin] = useState<string>("");
  const [agents, setAgents] = useState<Array<User>>();
  const { user } = useUser();
  const [selectedAgent, setSelectedAgent] = useState<User>(user);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const cart = useSelector<StoreProps, Array<CommandeArticleCartModel>>((state) => state?.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function closeSnackbarAction(snackbarId: SnackbarKey): ReactElement {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}>
        <CloseIcon />
      </IconButton>
    );
  }

  async function sendCommand(): Promise<void> {
    setIsSending(true);
    try {
      const commande: CommandeFormModel = {
        articles: cart,
        magasinDeRetrait: magasin,
      };

      if (user?.id !== selectedAgent.id) {
        await commandesService.createForAgent(commande, selectedAgent.id);
      } else {
        await commandesService.create(commande);
      }

      dispatch(deleteArticles());
      enqueueSnackbar("Votre commande a bien été envoyée", {
        action: closeSnackbarAction,
        variant: "success",
      });

      navigate(routesConfig.userCommand.path);
    } catch (e) {
      enqueueSnackbar("Une erreur est survenue lors de l'envoi", {
        action: closeSnackbarAction,
        variant: "error",
      });
    } finally {
      setIsSending(false);
    }
  }

  useEffect(() => {
    async function getOldMagasin(): Promise<void> {
      const res = await commandesService.getAllByMe();
      res.sort((a, b) => dateUtil.compareDesc(a.dateCommande, b.dateCommande));
      const oldMagasin = res[0].magasinDeRetrait ?? "";

      if (Object.values(MagasinDeRetrait).includes(oldMagasin as MagasinDeRetrait)) {
        setMagasin(oldMagasin);
      }
    }

    async function getAgents(): Promise<void> {
      const res = await graphService.getAllAgents();
      setAgents(res ?? []);
    }

    void getAgents();
    void getOldMagasin();
  }, []);

  return (
    <>
      <PageTitle title="Votre Panier" />
      <Container maxWidth="md">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <CartTable />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={isSmallScreen ? "h4" : "h3"} color="primary">
                Magasin de retrait :
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MagasinDeRetraitInput
                unique
                selectedCentreRetrait={magasin}
                setSelectedCentreRetrait={setMagasin}
                helperText="* Sélectionner un magasin de retrait est obligatoire pour passer commande."
                required
              />
            </Grid>
          </Grid>
          <Authorization roles={ApplicationRoles.SSTMANAGER}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant={isSmallScreen ? "h4" : "h3"} color="primary">
                  Commander les articles pour :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <AgentSelect
                  size="small"
                  agents={(agents as Array<User>) ?? []}
                  value={selectedAgent}
                  onValueChange={setSelectedAgent}
                />
              </Grid>
            </Grid>
          </Authorization>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <LoadingButton
              disabled={cart.length < 1 || magasin === "" || isSending}
              onClick={() => {
                void sendCommand();
              }}
              variant="contained"
              endIcon={<SendIcon />}
              loading={isSending}>
              Commander
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CartPage;
