import { Skeleton } from "@mui/material";
import { type PropsWithChildren, type ReactElement } from "react";
import PageTitle from "./PageTitle";

interface PageTitleProps extends PropsWithChildren {
  noBackButton?: boolean;
}

function PageTitleSkeleton({ noBackButton = false, children }: PageTitleProps): ReactElement {
  return (
    <PageTitle
      noBackButton={noBackButton}
      title={
        <Skeleton
          variant="text"
          animation="wave"
          sx={{
            display: "inline-flex",
            width: { xs: "50%", md: 500 },
            height: "50px",
          }}>
          {children}
        </Skeleton>
      }
    />
  );
}

export default PageTitleSkeleton;
